import React, { useState, useEffect} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './hail.css';
import ReactTooltip from 'react-tooltip';
import SwitchControl from './SwitchControl';
import { useCriteriaContext } from '../../context/criteria.context';
import { getSites } from '../../model/metadata';
import { IoAddCircleOutline } from 'react-icons/io5';
import AddHailForm from './addSite';
import { isAdminUser } from "../auth/userinfo";
import { postJSONData } from "../model/store.swr";
import styled from 'styled-components'
import SingleDropDownControl from '../useranalytics/singledropdown';
import StowSettings from './StowSettings';
import { domainsByCriteria } from '../layout/criteria';
import SiteMap from './SiteMap';
import ServiceForm from './Service';
import { FaLocationDot } from "react-icons/fa6";

// Styled component for displaying error messages
const Error = styled.span`
  padding: 0px;
  padding-top: 30px;
  color: red;
`;

const HailSettings = () => {
    // State variables for managing component state
  const [editable, setEditable] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
    // State variables for managing Hail Event settings and form data
  const criteria = useCriteriaContext();
  const domainArray = domainsByCriteria(criteria);
  const siteValueIndex = domainArray.indexOf('site');
  const siteValue = siteValueIndex !== -1 ? criteria.site : null;
  const [sites, setSites] = useState(null);
  const criteriaContext = useCriteriaContext();
  const [latitudeValue, setLatitudeValue] = useState('');
  const [longitudeValue, setLongitudeValue] = useState('');
  const [editedHailEnabled, setEditedHailEnabled] = useState(false);
  const [editedHailNotificationEnabled, setEditedHailNotificationEnabled] = useState(false);
  const [editedHailFeatureEnabled, setEditedHailFeatureEnabled] = useState(false);
  const [editedHailProbability, setEditedHailProbability] = useState('');
  const [editedHailSize, setEditedHailSize] = useState('');
  const [editedSiteRadius, setEditedSiteRadius] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dropdown1Value, setDropdown1Value] = useState('');
  const [dropdown2Value, setDropdown2Value] = useState('');
  const [dropdown3Value, setDropdown3Value] = useState('');
  const [showStowSettings, setShowStowSettings] = useState(true);
  const [fetchingData, setFetchingData] = useState(false);
  const [showSiteMap, setShowSiteMap] = useState(false); // New state variable for tracking whether to show the site map

  // Function to transform dropdown values for backend storage
  const transformDropdownValue = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    return numericValue;
  };

    // Function to transform dropdown values for backend storage
  const HailSizeOptions = ['0 inches','0.5 inches', '1 inches', '1.5 inches','2 inches'];
  const HailProbabilityOptions = ['0%','10%','20%','30%','40%', '50%', '60%', '70%', '80%', '90%', '100%'] ;
  const SiteRadiusOptions = ['5 miles','10 miles', '15 miles','20 miles','25 miles' ,'30 miles','35 miles' ,'40 miles'];
  
    // Function to format dropdown values for display
  const formatDropdownValue = (backendValue, options) => {
    const matchingOption = options.find(option => {
      const numericValue = backendValue.toString();
      return numericValue === option.replace(/[^0-9.]/g, '');
    });
    return matchingOption || ''; 
  };  
  
    // useEffect to set initial values when the component mounts or criteria/context changes
useEffect(() => {
  setFetchingData(true);
  const setInitialValues = () => {
    if (editable && criteriaContext.site && sites === null) {
      getSites(criteriaContext, (data) => {
        console.log('Data received:', data);
        setSites(data && data.length > 0 ? data : []);
      }, () => {});
    }

    if (criteriaContext.site && sites !== null) {
      const selectedSite = sites.find((site) => site.site_id === siteValue);

      if (selectedSite) {
        setLatitudeValue(selectedSite.latitude || '');
        setLongitudeValue(selectedSite.longitude || '');
        setEditedHailEnabled(selectedSite.auto_stow_enabled !== null ? selectedSite.auto_stow_enabled : false);
        setEditedHailNotificationEnabled(selectedSite.hail_notification_enabled !== null ? selectedSite.hail_notification_enabled : false);
        setEditedHailFeatureEnabled(selectedSite.hail_feature_enabled !== null ? selectedSite.hail_feature_enabled : false);

        if (selectedSite.hail_threshold) {
          try {
            const parsedThreshold = JSON.parse(selectedSite.hail_threshold);
            setEditedHailProbability(formatDropdownValue(parsedThreshold.hail_probability, HailProbabilityOptions));
            setEditedHailSize(formatDropdownValue(parsedThreshold.hail_size, HailSizeOptions));
            setEditedSiteRadius(formatDropdownValue(parsedThreshold.site_radius, SiteRadiusOptions));
            
            setDropdown1Value(formatDropdownValue(parsedThreshold.hail_size, HailSizeOptions));
            setDropdown2Value(formatDropdownValue(parsedThreshold.hail_probability, HailProbabilityOptions));
            setDropdown3Value(formatDropdownValue(parsedThreshold.site_radius, SiteRadiusOptions));
          } catch (error) {
            console.error('Error parsing hail_threshold:', error.message);
          }
        } else {
          setEditedHailProbability('');
          setEditedHailSize('');
          setEditedSiteRadius('');
          setDropdown1Value('');
          setDropdown2Value('');
          setDropdown3Value('');
        }
      }
    }
  };

  setInitialValues();
  setErrorMessage('');
  setFetchingData(false);
}, [editable, criteriaContext.site, sites, siteValue]);

    // useEffect to disable inputs and show error message if latitude or longitude is empty
  useEffect(() => {
    if (!latitudeValue || !longitudeValue) {
      setDisableInputs(true);
    } else {
      setDisableInputs(false);
      setErrorMessage(''); 
    }
  }, [latitudeValue, longitudeValue]);

    
  // Event handler for toggling Hail Notification switch
  const handleToggle1 = () => {
    if (!isAdminUser()) {
      if (!editedHailFeatureEnabled) {
        setErrorMessage("In order for users to make the necessary configuration changes, the site's hail feature must be enabled. To activate the Hail feature, please contact the FTC Admin");
        return;
      }
    }
    if (editable && !disableInputs) {
      setEditedHailNotificationEnabled(!editedHailNotificationEnabled);
      setUnsavedChanges(true);
    } else if (!isAdminUser()) {
      setErrorMessage("Hail Feature cannot be enabled as required Latitude and Longitude are not available. Please contact FTC Admin to fill up the required fields and try again.");
    } else if (isAdminUser()) {
      setErrorMessage("Feature cannot be enabled as required mandatory fields are missing.");
    };
  };

  // Event handler for toggling Auto Hail Stow switch
  const handleToggle2 = () => {
    if (!isAdminUser()) {
      if (!editedHailFeatureEnabled) {
        setErrorMessage("In order for users to make the necessary configuration changes, the site's hail feature must be enabled. To activate the Hail feature, please contact the FTC Admin");
        return;
      }
    }
    if (editable && !disableInputs) {
      setEditedHailEnabled(!editedHailEnabled);
      setUnsavedChanges(true);
    } else if (!isAdminUser()) {
      setErrorMessage("Hail Feature cannot be enabled as required Latitude and Longitude are not available. Please contact FTC Admin to fill up the required fields and try again.");
    } else if (isAdminUser()) {
      setErrorMessage("Feature cannot be enabled as required mandatory fields are missing.");
    };
  };

    // Event handler for toggling  Hail Feature switch
  const handleToggle3 = () => {
    if (!isAdminUser()) {
      return;
    }
      if (editable && !disableInputs) {
        setEditedHailFeatureEnabled(!editedHailFeatureEnabled);
        setUnsavedChanges(true);
      } else if (!latitudeValue.trim() || !longitudeValue.trim()) {
        setErrorMessage("Feature cannot be enabled as required mandatory fields are missing.");
      }
    };
  

  // Event handler for input changes in the form
  const handleInputChange = (e) => {

    if (editable) {
      const { name, value } = e.target || {};

      switch (name) {
        case 'field2':
          setLatitudeValue(value);
          break;
        case 'field3':
          setLongitudeValue(value);
          break;
        case 'field4':
          setEditedHailSize(value);
          break;
        case 'field5':
          setEditedHailProbability(value);
          break;
        case 'field6':
          setEditedSiteRadius(value);
          break;
        default:
          break;
      }
      setUnsavedChanges(true);
    }
  };

  const handleDropdownChange = (name, value) => {

    const latitudeString = String(latitudeValue);
    const longitudeString = String(longitudeValue);

    if (!isAdminUser()) {
      if (!editedHailFeatureEnabled) {
        setErrorMessage("In order for users to make the necessary configuration changes, the site's hail feature must be enabled. To activate the Hail feature, please contact the FTC Admin");
        return;
      }
    }

    if (
      isAdminUser() &&
      (['field4', 'field5', 'field6'].includes(name)) &&
      (!latitudeString.trim() || !longitudeString.trim())
    ) {
      setErrorMessage("Feature cannot be enabled as required mandatory fields are missing.");
      return;
    } else if(!isAdminUser() && (['field4', 'field5', 'field6'].includes(name)) &&(!latitudeString.trim() || !longitudeString.trim()))
    {
      setErrorMessage("Hail Feature cannot be enabled as required Latitude and Longitude are not available. Please contact FTC Admin to fill up the required fields and try again.");
      return;
    }
  
    if (name === 'field4') {
      setDropdown1Value(value);
    } else if (name === 'field5') {
      setDropdown2Value(value);
    } else if (name === 'field6') {
      setDropdown3Value(value);
    }
  
    handleInputChange({ target: { name, value } });
    setUnsavedChanges(true);
  };
  
  // Event handler for submitting form data
  const handleOk = () => {
    setToggle1(editedHailEnabled);
    setToggle2(editedHailNotificationEnabled);
    setToggle3(editedHailFeatureEnabled)

      // Check for null values in dropdowns
      if (!isAdminUser() &&
        (dropdown1Value === '' ||
        dropdown2Value === '' ||
        dropdown3Value === '')
      ) {
        setErrorMessage("One or more of the required fields is empty. Please provide a threshold value to generate Hail Stow forecast.");
        return;
      }    

    const postData = {
      siteValue,
      editedHailEnabled,
      editedHailNotificationEnabled,
      editedHailFeatureEnabled,
      editedHailProbability: transformDropdownValue(dropdown2Value),
      editedHailSize: transformDropdownValue(dropdown1Value),
      editedSiteRadius: transformDropdownValue(dropdown3Value),
      latitudeValue, 
      longitudeValue,
    };
    console.log('Data submitted:', postData);
    const apiUrl = "/meta?querytype=meta_sites_hail_update"; 
    postJSONData(apiUrl, postData, (response) => {
      console.log('Server response:', response);

      getSites(
        criteriaContext,
        (data) => {
          console.log('Data received:', data);
          setSites(data && data.length > 0 ? data : []);
        }, () => {}
      );
      handleSuccessfulResponse(response);

      setUnsavedChanges(false);
      setEditable(true);
      setErrorMessage("");
    }, () => {});
  };

  // Placeholder function for handling successful responses
  const handleSuccessfulResponse = (response) => {
  };

  // Event handler for canceling the form submission
  const handleCancel = () => {
    console.log('Form canceled');
    setEditable(true);
    getSites(
      criteriaContext,
      (data) => {
        console.log('Data received:', data);
        setSites(data && data.length > 0 ? data : []);
      }, () => {}
    );
    setUnsavedChanges(false);
    setErrorMessage(""); // Clear the error message when toggle is enabled
  };

  // Event handler for showing the Add Site form
  const handleAddClick = () => {
    setShowAddForm(true);
    setShowStowSettings(false);
  };


    // Event handler for canceling the Add Site form submission
  const handleCancelAddForm = () => {
    setShowAddForm(false);
    setShowStowSettings(true);
  };
  
  useEffect(() => {
    if (showAddForm && siteValue) {
      handleCancelAddForm();
    }
  }, [siteValue]);

    // Event handler for submitting the Add Site form
  const handleAddFormSubmit = (formData) => {
    console.log('Submitted data:', formData);
    const apiUrl = "/meta?querytype=meta_sites_hail_form";
    postJSONData(apiUrl, formData, (response) => {
      console.log('Server response:', response);
      getSites(
        criteriaContext,
        (data) => {
          console.log('Data received:', data);
          setSites(data && data.length > 0 ? data : []);
        }, () => {}
      );
      handleSuccessfulResponse(response);
    });
  };

  return (
    <>
    <Row>
      <div className="col-8 col-xl-5 pl-0" style={{ width: 'calc(100% - 20px)' }}>
      {(siteValue)? (
        showAddForm ? (
          <AddHailForm onCancel={handleCancelAddForm} onSubmit={handleAddFormSubmit} />
        ) : (
          <div className="chart-card1">
            <div className="chart-card-header1">
              <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 0px 10px 6px' }}>
                Hail Event Settings for "{siteValue}"
              </h5>
            </div>
            <Row></Row>
            {fetchingData && <p style={{ textAlign: 'center', color: '#4C4345', marginTop: '10px' }}>Loading...</p>}
            <Row>
              <Col md={9}></Col>
              <Col md={3} className={isAdminUser() ? 'col-padding' : 'col-padding-right'}>
                {isAdminUser() && (
                  <span
                    className="tooltip-icon"
                    data-tip="<div class='tooltip-content'><b>Add Site</b></div>"
                    data-html={true}
                    data-place="left"
                    onClick={handleAddClick}
                  >
                    <IoAddCircleOutline size={25} />
                  </span>
                )}
                <span
                  className="tooltip-icon"
                  data-tip="<div class='tooltip-content'><b>IMPORTANT:</b><br />In order to automatically stow FTC trackers based on these notifications, <br/>the Auto Hail Stow setting must be toggled ON. <br/>If the customer's preference is to not stow automatically based on these notifications, <br/>then the Auto Hail Stow setting should be toggled OFF.</div>"
                  data-html={true}
                  data-place="right"
                >
                  <img
                    src="../assets/img/icon/icon-info.svg"
                    width="20"
                    height="20"
                    size={31}
                    alt="Info"
                  />
                </span>
                <ReactTooltip effect="solid" />
              </Col>
            </Row>
            <Row></Row>
            <Row>
                <Col md={12}>
                  {errorMessage && <Error>{errorMessage}</Error>}
                </Col>
            </Row>
            <Row></Row>
            
            <Row className="d-flex align-items-center">
                {editable && (isAdminUser() || editedHailFeatureEnabled) && (
                  <>
                    <Col md={7}>
                      <label className="card-title">Hail Feature Enabled</label>
                    </Col>
                    <Col md={5} style={{ paddingLeft: '8px' }}>
                      <SwitchControl
                        value={editedHailFeatureEnabled}
                        handleChange={handleToggle3}
                        disabled={!editable || disableInputs}
                        isAdmin={!isAdminUser()}
                        />
                    </Col>
                  </>
                )}
              </Row>

            <Row className="d-flex align-items-center">
              <Col md={7}>
                <label className="card-title">Auto Stow Enabled</label>
              </Col>
              <Col md={5} style={{ paddingLeft: '8px' }}>
                <SwitchControl
                  value={editedHailEnabled}
                  handleChange={handleToggle2}
                  disabled={!editable}
                />
              </Col>
            </Row>

            <Row className="d-flex align-items-center">
              <Col md={7}>
                <label className="card-title">Hail Notifications Enabled</label>
              </Col>
              <Col md={5} style={{ paddingLeft: '8px' }}>
                <SwitchControl
                  value={editedHailNotificationEnabled}
                  handleChange={handleToggle1}
                  disabled={!editable}
                />
              </Col>
            </Row>


            <Row className="d-flex align-items-center">
              <Col md={7}>
                <label className="card-title">Latitude<span style={{ color: 'red', paddingLeft: '4px' }}>*</span></label>
              </Col>
              <Col md={5}>
                {isAdminUser() ? (
                  <Form.Control
                    type="number"
                    name="field2"
                    value={latitudeValue}
                    onChange={handleInputChange}
                    disabled={!editable}                  
                    style={{
                      background: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '8px',
                      paddingRight: '18px',
                    }}
                  />
                ) : (
                  latitudeValue ? (
                    <label className="card-title"><b>{latitudeValue}</b></label>
                  ) : (
                    <label className="card-title">Not Available</label>
                  )
                )}
                {isAdminUser() && <div className="unit"></div>}
              </Col>
            </Row>

            <Row className="d-flex align-items-center">
              <Col md={7}>
                <label className="card-title">Longitude<span style={{ color: 'red', paddingLeft: '4px' }}>*</span></label>
              </Col>
              <Col md={5}>
                {isAdminUser() ? (
                  <Form.Control
                    type="number"
                    name="field3"
                    value={longitudeValue}
                    onChange={handleInputChange}
                    disabled={!editable}                  
                    style={{
                      background: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '8px',
                      paddingRight: '18px',
                    }}
                  />
                ) : (
                  longitudeValue ? (
                    <label className="card-title"><b>{longitudeValue}</b></label>
                  ) : (
                    <label className="card-title">Not Available</label>
                  )
                )}
                {isAdminUser() && <div className="unit"></div>}
              </Col>
            </Row>

            <Row className="d-flex align-items-center">
              <Col md={12}>
                <label className="mb-3 chart-card-header"><b>Hail Event Thresholds:</b></label>
                  <span
                    className="tooltip-icon"
                    data-tip="<div class='tooltip-content'><b>IMPORTANT:</b><br />The user-configured Forecast Radius, in conjunction with parameters such as Hail Size and Hail Probability, 
                    <br/> plays a crucial role in issuing warnings for potential Hail events.
                    <br/><br/>
                    It's important that the Forecast Radius encompasses the site boundary and includes a buffer
                    <br/>zone to allow ample time for the trackers to rotate and stow in response to hail.
                    <br/><br/>
                    A forecast is generated when the threshold for Hail Size and Hail Probability is reached or exceeded.</div>"
                    data-html={true}
                    data-place="right"
                  >
                    <img
                      src="../assets/img/icon/icon-info.svg"
                      width="15"
                      height="15"
                      size={22}
                      alt="Info"
                    />
                  </span>
              </Col>
            </Row>

            <Row className="d-flex align-items-center">
                <Col md={7}>
                  <label className="card-title">Hail Size{!isAdminUser() && <span style={{ color: 'red', paddingLeft: '4px' }}>*</span>}</label>
                </Col>
                <Col md={5}>
                <SingleDropDownControl
                  options={HailSizeOptions.map((option) => ({ value: option, label: option }))}
                  value={{ value: dropdown1Value, label: dropdown1Value }}
                  handleChange={(selectedOption) => handleDropdownChange('field4', selectedOption.value)}
                />
                </Col>
            </Row>

            <Row className="d-flex align-items-center">
                <Col md={7}>
                <label className="card-title">Hail Probability{!isAdminUser() && <span style={{ color: 'red', paddingLeft: '4px' }}>*</span>}</label>            
                </Col>
                <Col md={5}>
                <SingleDropDownControl
                    options={HailProbabilityOptions.map((option) => ({ value: option, label: option }))}
                    value={{ value: dropdown2Value, label: dropdown2Value }}
                    handleChange={(selectedOption) => handleDropdownChange('field5', selectedOption.value)}
                  />
                </Col>
            </Row>

            <Row className="d-flex align-items-center">
                <Col md={7}>
                  <label className="card-title">Forecast Radius{!isAdminUser() && <span style={{ color: 'red', paddingLeft: '4px' }}>*</span>}</label>
                </Col>
                <Col md={5}>
                <SingleDropDownControl
                  options={SiteRadiusOptions.map((option) => ({ value: option, label: option }))}
                  value={{ value: dropdown3Value, label: dropdown3Value }}
                  handleChange={(selectedOption) => handleDropdownChange('field6', selectedOption.value)}
                />
                </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row className="d-flex align-items-center">
                <Col md={7}></Col>
                <Col md={5}>
                  <Button 
                    className="btn-secondary" 
                    onClick={handleOk} 
                    style={{ width: '47%' }}
                    disabled={disableInputs || !unsavedChanges}
                  >
                    Ok
                  </Button>
                  <Button 
                    className="btn-secondary" 
                    style={{ width: '47%', marginLeft: '11px' }} 
                    onClick={handleCancel}
                    disabled={!unsavedChanges}
                  >
                    Cancel
                  </Button>
                </Col>
            </Row>
          </div>
          )
          ) : (
            <Error>Setting page is only accessible at the Site level. Please choose a Site.</Error>
        )}
      </div>
      <div className="col" style={{ position: 'relative', height: !isAdminUser() ? '57vh' : '61vh' }}>
        {!showAddForm && siteValue && showSiteMap ? (
          <SiteMap siteValue={siteValue} latitude={latitudeValue} longitude={longitudeValue} radius={editedSiteRadius} />
        ) : (
          !showAddForm && siteValue && (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
              <span className="tooltip-icon" onClick={() => setShowSiteMap(true)}>
                <FaLocationDot size={35} color="red"/>
              </span>         
              <a href="#" onClick={() => setShowSiteMap(true)} style={{ fontSize: '17px', display: 'block', marginTop: '10px' }}>
                Click here to preview the map.
              </a>
            </div>
          )
        )}
    </div>
    </Row>    
      <Row></Row>
      <Row></Row>
        <Row>
          {showStowSettings && <StowSettings handleOk={handleOk}/>}
          <div className ="col">
          {siteValue && !showAddForm && isAdminUser() && <ServiceForm/>}
          </div>
        </Row>
    <Row></Row>
    </>
  );
};

export default HailSettings;
