import React from 'react';

export const TooltipHelpContent = {
    PortfolioSummary: {
        header: "Portfolio Summary",
        body: <div>
            <p>Details for selected portfolio</p>
            <p>including number of sites,</p>
            <p>average uptime and error occurences</p>
            <p>for the current time range.</p>
        </div>
    },
    SiteSummary: {
        header: "Site Summary",
        body: <p>Details for selected site including number of zones, average uptime and error occurences for the current time range.</p>
    },
    ZoneSummary: {
        header: "Zone Summary",
        body: <p>Details for selected zone including number of trackers, average uptime and error occurences for the current time range.</p>
    },
    RCSummary: {
        header: "RC Summary",
        body: <p>Details for selected rc including average uptime for the current time range.</p>
    },
    UptimeOverview: {
        header: "Positional Accuracy Overview",
        body:   <div>
            <p>Average positional accuracy for this portfolio,</p>
            <p>site, zone or tracker over the last 7 days.</p>
            <p>A tracker is defined as positionally accurate when it</p>
            <p>is within 5 degrees of its target angle</p>
            <p>when operating in Auto mode.</p>
        </div> 

    },
    AvailabilityOverview: {
        header: "Tracker Availability Overview",
        body:   <div>
            <p>Average tracker availability for this portfolio,</p>
            <p>site, zone or tracker over the last 7 days.</p>
            <p>Tracker availability is a conservative metric defined</p>
            <p>by FTC Solar that requires a tracker to be functioning</p>
            <p>in specific modes, communicating, and error-free</p>
            <p>to be considered available.</p> 
        </div>
    },
    AvailabilityOverviewForCriteria: {
        header: "Tracker Availability Overview",
        body:   <div>
            <p>Average tracker availability for this portfolio,</p>
            <p>site, zone or tracker for selected time criteria.</p>
            <p>Tracker availability is a conservative metric defined</p>
            <p>by FTC Solar that requires a tracker to be functioning</p>
            <p>in specific modes, communicating, and error-free</p>
            <p>to be considered available.</p> 
        </div>
    },
    RCsWithErrors: {
        header: "Tracker Error Summary",
        body:   <div>
            <p>Number of unique trackers in this portfolio,</p>
            <p>site or zone experiencing errors</p>
            <p>within the last 60 minutes.</p>    
        </div>
    },
    MaintenanceOverview: {
        header: "Maintenance activities",
        body:   <div>
            <p>Number of maintenance activities on selected</p>
            <p>portfolio, site, zone or tracker within the time</p>
            <p>period selected.</p>    
        </div>
    },
    CurrentMode: {
        header: "Current Mode",
        body: <p>Mode of operation experienced by the majority of trackers in this portfolio, site or zone within the last 60 minutes.</p>
    },
    ZonesOverview: {
        header: "Zones Communicating",
        body:   <div>
            <p>Number of zone controllers in this portfolio</p>
            <p>that have communicated to the cloud</p>
            <p>within the last 30 minutes.</p>
        </div> 
    },
    RowsOverview: {
        header: "Trackers Offline",
        body:   <div>
            <p>Trackers that have not communicated in the last 60 minutes are tagged as offline and displayed on this card.</p> 
            <p >Please note that the tracker count on this offline card does not include trackers from site(s) or zone(s) </p>
            <p style={{ marginBottom: '15px' }}>that are not reporting data to SUNOPS.</p>
            <p style={{ marginBottom: '5px' }}><strong>Offline Tracker Table View:</strong></p>
            <p>The offline tracker detail view lists all offline trackers for the selected sites or zones.</p>
            <p style={{ marginBottom: '10px' }}>The sites or zones listed in this table may or may not be communicating or active.</p>
            <ul>
                <li><p>The "Last Reported Time" indicates the last communication made with the tracker.</p></li>
                <li><p>The "Last Reported Alerts" displays the most recent active alerts experienced by the tracker.</p></li>
                <li><p>The dropdown for sites/zones indicates whether the site/zone is communicating.</p></li>
                <p>Red indicates the site/zone is not communicating, while Green indicates the site/zone is currently communicating.</p>
            </ul>
            </div>
    },

    AverageAngle: {
        header: "Average Angle",
        body: <p>Average tracker angle for this site, zone or tracker within the last 60 minutes.</p>
    },
    UptimeSummary: {
        header: "Historical Tracker Performance",
        body: <p>Calculated tracker availability and positional accuracy for this portfolio, site, zone or tracker over the current time range.</p>
    },
    RCPerformance: {
        header: "Tracker Performance Summary",
        body: <div>
            <p>Describes the relationship between tracker availability and tracker errors.</p>
            <p>Higher performing sites, zones and trackers will appear in the upper left.  Lower performers will appear in the lower right.</p>
        </div>
    },
    UptimeImpact: {
        header: "Availability Impact Summary",
        body: <div>
            <p>Highlights the sites, zones or trackers that are contributing the most to tracker unavailability (depending on the current context).</p>
            <p>Addressing the issues in devices associated with each of the bars in the chart will help the parent (portfolio, site or zone) reach 99% availability.</p>
            </div>
    },
    ErrorSummary: {
        header: "Historical Tracker Errors",
        body: <p>Errors reported by individual trackers within the current time range.</p>
    },
    ModeSummary: {
        header: "Historical Tracker Modes",
        body: <p>Modes of operation reported by individual trackers within the current time range.</p>
    },
    WindSpeedSummary: {
        header: "Wind Speed by Zone",
        body: <p>Wind speed measured in meters per second by the local anemometer at each zone controller within the current time range.</p>
    },
    WindDirectionSummary: {
        header: "Wind Direction by Zone",
        body: <p>Wind direction measured in degrees by the local anemometer at each zone controller within the current time range.</p>
    },
    FloodLevelSummary: {
        header: "Flood Level by Zone",
        body: <p>Water level measured in millimeters at each zone controller within the current time range.</p>
    },
    SnowLevelSummary: {
        header: "Snow Fall by Zone",
        body: <p>Depth of snow measured in millimeters at each zone controller within the current time range.</p>
    },
    AngleSummary: {
        header: "Historical Tracker Angles",
        body: <div>
            <p>Tilt angles reported by individual trackers within the current time range.</p>
            <p>Negative angles indicate east facing modules.  Positive angles indicate west facing modules.</p>
            </div>
    },
    AnomalousAngleSummary: {
        header: "Anomalous Trackers",
        body: <div>
            <p>Zones or trackers exhibiting anomalous behavior within the current time range.</p>
            <p>Typically anomalous trackers will have lower availability and will be out of alignment with other trackers as shown in the historical angle chart.</p>
            </div>
    },
    ActivePowerSummary: {
        header: "Historical Active Power",
        body: <div>
            <p>Active Power reported by individual Inverters within the current time range.</p>
            </div>
    },
    EnergyTimeline: {
        header: "Energy Production Timeline",
        body: <div>
            <p>Energy Production Timeline based on actual and expected values.</p>
            </div>
    },
    PowerTimeline: {
        header: "Power Timeline",
        body: <div>
            <p>Power Timeline based on actual and expected power values.</p>
            </div>
    },
    EnergyProduced: {
        header: "Energy Produced",
        body: <div>
            <p>Total energy produced within the current time range.</p>
            </div>
    }, 
    EnergyProduction: {
        header: "Energy Production",
        body: <div>
            <p>Energy Production based on Actual and Expected Energy Values.</p>
            </div>
    },
	 WeatherOverview: {
        header: "Weather Forecast",
        body: <div>
            <p>Weather forecast for the next 7 days based on the latitude and longitude of the site.</p>
            <p>Two dashes (--) indicates that the wind gust or wind speed data is unavailable in the forecast.</p>

            </div>
    },
    TrackerOfflineOverview: {
        header: "Tracker Offline Overview",
        body: <div>
            <p>description here</p>
            </div>
    }
}
