import React, { useState, useEffect} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import './diffuse.css';
import DisableOptimization from './disableOptimization';
import { getSites } from '../../model/metadata';
import { domainsByCriteria } from '../layout/criteria';
import { useCriteriaContext } from '../../context/criteria.context';
import { postJSONData } from "../model/store.swr";
import { getCrumbsData } from './crumbsData';
import MultiSelectDropDownControl from './multiSelectDropdown';
import IrradianceSettings from './configurationSettings';
import ReactTooltip from 'react-tooltip';
import { isAdminUser } from "../auth/userinfo";

// Styled component for displaying error messages
const Error = styled.span`
  padding: 0px;
  padding-top: 30px;
  color: red;
`;
// Styled components for custom radio buttons
const CustomRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const CustomRadioWrapper1 = styled.div`
  display: flex;
  flex-direction: row;  // Changed from column to row
`; 
const CustomRadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 13px;
`;

const CustomRadioLabel1 = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;  // Added margin-right for spacing
  margin-top: 10px;  // Added margin-top for spacing
  cursor: pointer;
  font-size: 13px;
`;

const CustomRadioInput = styled.input`
  appearance: none;
  background-color: #fff;
  border: 2px solid #01668D;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 10px;

  &:checked::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #01668D;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const DiffuseIndex = () => {
    const [fetchingData, setFetchingData] = useState(false);
    const criteria = useCriteriaContext();
    const domainArray = domainsByCriteria(criteria);
    const siteValueIndex = domainArray.indexOf('site');
    const siteValue = siteValueIndex !== -1 ? criteria.site : null;
    const [sites, setSites] = useState(null);
    const criteriaContext = useCriteriaContext();
    const [diffuseRoatationLimit, setDiffuseRotationLimit] = useState('');
    const [initialDiffuseRotationLimit, setInitialDiffuseRotationLimit] = useState('');
    const [initialSelectedZone, setInitialSelectedZone] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [selectedOption, setSelectedOption] = useState('site_id');
    const [initialDiffuseStatus, setInitialDiffuseStatus] = useState('disabled');
    const [diffuseStatus, setDiffuseStatus] = useState('');
    const portfoliosIndex = domainArray.indexOf('pf');
    const portfoliosValue = portfoliosIndex !== -1 ? criteria.pf : null;
    const [selectedZone, setSelectedZone] = useState(null);
    const [zones, setZones] = useState([]);
    const [error, setError] = useState('');
    const isAdmin = isAdminUser();

    // Fetch initial data when criteria or sites change
    useEffect(() => {
      const fetchInitialData = async () => {
        setFetchingData(true);
        if (siteValue && sites === null) {
          getSites(criteriaContext, (data) => {
            setSites(data && data.length > 0 ? data : []);
          }, () => {});
        }
  
        if (siteValue && sites !== null) {
          const selectedSite = sites.find((site) => site.site_id === siteValue);
          if (selectedSite) {
            const status = selectedSite.diffuse_status || 'Disabled';
            const rotationLimit = selectedSite.diffuse_rotation_limit || 60;
            setDiffuseStatus(status);
            setDiffuseRotationLimit(rotationLimit);
            setInitialDiffuseStatus(status);
            setInitialDiffuseRotationLimit(rotationLimit);
          }
        }
        setFetchingData(false);
      };
        fetchInitialData();
    }, [criteriaContext.site, sites, siteValue, selectedOption]);

    // Reset selected option to 'site_id' when site value changes
      useEffect(() => {
        setSelectedOption('site_id');
    }, [siteValue]);

      // Reset selected zone when site value changes
      useEffect(() => {
        setSelectedZone('')
    }, [siteValue]);

    useEffect(() => {
      if (selectedOption === 'zone_id') {
        setDiffuseStatus('');
      }},[selectedOption]);

      useEffect(() => {
        if (selectedOption === 'site_id') {
          // Clear zone-related state when switching to site level
          setDiffuseStatus(initialDiffuseStatus);
          setDiffuseRotationLimit(initialDiffuseRotationLimit);
          setSelectedZone(initialSelectedZone);
        }
      }, [selectedOption, initialDiffuseStatus, initialDiffuseRotationLimit, initialSelectedZone]);
      
    // Handle change in diffuse status
    const handleStatusChange = (event) => {
      if (isAdmin) {
      setDiffuseStatus(event.target.value);
      setIsChanged(true);
      }
    };

    // Handle change in diffuse rotation limit
    const handleDelayRotationChange = (event) => {
      if (isAdmin) {
      setDiffuseRotationLimit(event.target.value);
      setIsChanged(true);
      }
    };

    const handleSave = () => {
      let payload;
      // If selected option is site_id, then post data.
        if (selectedOption === 'site_id') {
          payload = {
            selectedOption,
            site_id: siteValue,
            diffuse_status: diffuseStatus,
            diffuse_rotation_limit: diffuseRoatationLimit,
          };
        } else if (selectedOption === 'zone_id' && selectedZone) {
          const zoneStatus = selectedZone.reduce((acc, zone) => {
            acc[zone.value] = diffuseStatus;
            return acc;
          }, {});

      // If selected option is zone_id, then post data.
        payload = {
          selectedOption,
          site_id: siteValue,
          zones: JSON.stringify(zoneStatus), 
          diffuse_rotation_limit: diffuseRoatationLimit,
        };
      }
        console.log('Data submitted:', payload);
        const apiUrl = "/meta?querytype=meta_diffuse_status&restype=json"; 
          postJSONData(apiUrl, payload, (response) => {
            console.log('Server response:', response);

            if (selectedOption === 'site_id') {
              getSites(criteriaContext, (data) => {
                console.log('Data received:', data);
                setSites(data && data.length > 0 ? data : []);
            }, () => {});
          }else{
            fetchInitialZoneData();
          }
          setIsChanged(false);
          });

        // if selected option is site_id, then post data to /diffuse-pulsar-data
          const data = {
            site_id: siteValue,
            diffuse_status: diffuseStatus,
          }
            postJSONData("/diffuse-pulsar-data?restype=json",data,(res) => {console.log("checking response",res)})
    };

    const handleCancel = () => {
      if(selectedOption === 'zone_id'){
        fetchInitialZoneData();
        setIsChanged(false);
      }else{
      setDiffuseStatus(initialDiffuseStatus);
      setDiffuseRotationLimit(initialDiffuseRotationLimit);
      setSelectedZone(initialSelectedZone); 
      setIsChanged(false);
    }
    };

    // Fetch zone data when selected option is 'zone_id'
    const fetchInitialZoneData = async () => {
      setFetchingData(true);
      getSites(criteriaContext, (data) => {
          setSites(data && data.length > 0 ? data : []);
          if (data) {
              const selectedSite = data.find((site) => site.site_id === siteValue);
              if (selectedSite) {
                  const rotationLimit = selectedSite.diffuse_rotation_limit || 60;
                  const statusZone = JSON.parse(selectedSite.diffuse_status_zone || "{}");
                  const selectedZones = Object.entries(statusZone)
                  .map(([zoneId, status]) => ({ value: zoneId, label: zoneId, status: status }));
                  console.log('Selected Zones:', selectedZones);
                  console.log('Status:', statusZone);
                  const selectedZoneEnabled = selectedZones.filter(zone => zone.status !== 'Disabled');
                  console.log('Selected Zone Enabled:', selectedZoneEnabled);
                  setDiffuseRotationLimit(rotationLimit);
                  setInitialDiffuseRotationLimit(rotationLimit);
                  setSelectedZone(selectedZoneEnabled);
                  setInitialSelectedZone(selectedZoneEnabled);
  
                  // If only one zone is selected, set diffuseStatus accordingly
                    if (selectedZoneEnabled.length === 1) {
                      const zoneId = Object.keys(selectedZoneEnabled)[0];
                      setDiffuseStatus(selectedZoneEnabled[zoneId].status);
                      console.log('selected Diffuse Status:', selectedZoneEnabled[zoneId].status);
                    } else {
                      setDiffuseStatus('');
                    }                    
              }
          }
          setFetchingData(false);
      }, () => {});
  };

    // Handle change in selected option (site or zone level)
      const handleOptionChange = (e) => {
        if (isAdmin) {
          const newSelectedOption = e.target.value;
          setSelectedOption(newSelectedOption);
      
          if (newSelectedOption === 'zone_id') {
            const payload = { portfolios: '', user: '', pf: portfoliosValue, site: siteValue };
            getCrumbsData(payload, setZones, setError);
            fetchInitialZoneData();
          } else if (newSelectedOption === 'site_id') {
            // Reset state variables related to zone when switching back to site level
            setDiffuseStatus(initialDiffuseStatus);
            setDiffuseRotationLimit(initialDiffuseRotationLimit);
            setSelectedZone(initialSelectedZone);
          }
        }
      };
      
    // Handle change in selected zone
      const handleZoneChange = (selectedOption) => {
        if (isAdmin) {
        setSelectedZone(selectedOption);
        setIsChanged(true);

        if (selectedOption && selectedOption.length === 1) {
          const selectedZoneId = selectedOption[0].value;
          const statusZone = JSON.parse(sites.find((site) => site.site_id === siteValue).diffuse_status_zone || "{}");
          console.log('Selected Zone:', selectedZoneId);
          console.log('Status:', statusZone);
          setDiffuseStatus(statusZone[selectedZoneId] || '');
        } else {
          setDiffuseStatus('');
        }
      }
    };

    return (
      <>
      { siteValue ? (
        <>
        <Row>
        <div className="col-6 pl-0">
        <div className="chart-card1">
            <div className="chart-card-header1">
              <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 0px 10px 6px' }}>
                Irradiance Optimization Settings
              </h5>
            </div>
            <Row></Row>
            {!isAdmin &&<Error>The Irradiance status can be toggled ON/OFF only by FTC-Admin. Please contact FTC-Admin to access the Irradiance feature.</Error>}
            <Row></Row>
            <Row>
              <Col md={10}></Col>
              <Col md={2}>
                <span
                  className="tooltip-icon"
                  data-tip="<div class='tooltip-content'>The Irradiance status can be configured at both the Site and Zone levels.
                  <br/>It is recommended to first update the Irradiance settings at the Site level
                  <br/>and then proceed to the Zone level.<br/>
                  </div>"
                  data-html={true}
                  data-place="right"
                >
                  <img
                    src="../assets/img/icon/icon-info.svg"
                    width="20"
                    height="20"
                    size={31}
                    alt="Info"
                  />
                </span>
                <ReactTooltip effect="solid" />
              </Col>
            </Row>
            <Form.Group as={Row}> 
                <Form.Label className="card-title" column sm={5}>
                Status Level
                </Form.Label>
                <Col sm={6}>
                  <CustomRadioWrapper1>
                    <CustomRadioLabel1>
                      <CustomRadioInput
                        type="radio"
                        name="selectOption"
                        value="site_id"
                        checked={selectedOption === 'site_id'}
                        onChange={handleOptionChange}
                      />
                      Site Level
                    </CustomRadioLabel1>
                    <CustomRadioLabel1>
                      <CustomRadioInput
                        type="radio"
                        name="selectOption"
                        value="zone_id"
                        checked={selectedOption === 'zone_id'}
                        onChange={handleOptionChange}
                      />
                      Zone Level
                    </CustomRadioLabel1>
                  </CustomRadioWrapper1>
                </Col>
                <Col sm={1}></Col>
              </Form.Group>
              {selectedOption === 'zone_id' && (
              <Form.Group as={Row}>
                <Form.Label className="card-title" column sm={5}>
                  Select Zone(s)
                </Form.Label>
                <Col sm={6}>
                  <MultiSelectDropDownControl
                    options={zones}
                    selectedOptions={selectedZone}
                    onChange={handleZoneChange}
                  />
                </Col>
                <col sm={1}></col>
              </Form.Group>
            )}
            <Row>
            <Form className="w-100">
                            <Form.Group as={Row}>
                                <Form.Label  className="card-title" column sm={5}>
                                Status
                                </Form.Label>
                                <Col sm={7}>
                                    <CustomRadioWrapper>
                                        <CustomRadioLabel>
                                        <CustomRadioInput
                                                type="radio"
                                                name="status"
                                                value="Enable on all days"
                                                checked={diffuseStatus === 'Enable on all days'}
                                                onChange={handleStatusChange}
                                                />
                                            Enable on all days
                                        </CustomRadioLabel>
                                        <CustomRadioLabel>
                                        <CustomRadioInput
                                                type="radio"
                                                name="status"
                                                value="Enable on even days"
                                                checked={diffuseStatus === 'Enable on even days'}
                                                onChange={handleStatusChange}
                                                />
                                            Enable on even days
                                        </CustomRadioLabel>
                                        <CustomRadioLabel>
                                        <CustomRadioInput
                                                type="radio"
                                                name="status"
                                                value="Enable on odd days"
                                                checked={diffuseStatus === 'Enable on odd days'}
                                                onChange={handleStatusChange}
                                                />
                                            Enable on odd days
                                        </CustomRadioLabel>
                                        <CustomRadioLabel>
                                            <CustomRadioInput
                                                type="radio"
                                                name="status"
                                                value="Disabled"
                                                checked={diffuseStatus === 'Disabled'}
                                                onChange={handleStatusChange}
                                            />
                                            Disabled
                                        </CustomRadioLabel>
                                    </CustomRadioWrapper>
                                </Col>
                            </Form.Group>
                            <Row></Row>
                            <Form.Group as={Row}>
                                <Form.Label className="card-title" column sm={5}>
                                Daily Rotation Limit
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                        type="number"
                                        value={diffuseRoatationLimit}
                                        onChange={handleDelayRotationChange}
                                    />
                                        <div className="unit">Degree</div>
                                </Col>
                                <Col sm={1}></Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                            <Col md={5}></Col>
                                <Col md={6}>
                                  <Button className="btn-secondary" onClick={handleSave} style={{ width: '47%' }} disabled={!isChanged} >
                                    Save
                                  </Button>
                                  <Button className="btn-secondary" style={{ width: '47%', marginLeft: '11px' }} onClick={handleCancel} disabled={!isChanged}>
                                    Cancel
                                  </Button>
                                </Col>
                                <Col sm={1}></Col>
                            </Form.Group>
                        </Form>
                    </Row>
              </div>
          </div>
          <IrradianceSettings siteValue={siteValue} diffuseStatus={diffuseStatus} />
        </Row>
        <Row></Row>
        <Row></Row>
        <Row>
          <DisableOptimization diffuseStatus={diffuseStatus} />
        </Row>
        </>
        ) : (
          <Error>Sunpath page is only accessible at the Site level. Please choose a Site.</Error>
        )}
        </>
    );
}

export default DiffuseIndex;