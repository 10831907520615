import React from 'react';

export const TooltipContent = {
    IssueSummary: {
        header: "Issue Summary",
        body: (
            <div>
                <p>
                    <p>
                        The 'Issue Summary Table' is a structured representation
                        containing summarized information about various errors,
                        alerts, and data gap issues that
                    </p>
                    <p>
                        Portfolio/Site/Zone/Trackers are experiencing for the
                        selected time range." By default, the table is sorted
                        based on the Availability impact.
                    </p>
                </p>
                <p>Users are also allowed to sort based on their preference.</p>
                <p>The table provides data on:</p>
                <p>
                    <b>Avg. Duration (Hours) :</b>
                    <p>
                        The Avg. Duration (Hours) is the average amount of time,
                        measured in hours, that a particular error, alert, or
                        data gap is active for.
                    </p>
                    <p>
                        It provides insight into the typical duration of an
                        occurrence over a specified period.
                    </p>
                </p>
                <p>
                    <b>Max. Duration  :</b>
                    <p>
                        The Max. Duration  is the maximum amount of time,
                        measured for a particular error, alert, or
                        data gap is active for.
                    </p>
                    <p>
                        It provides insight into the typical duration of an
                        occurrence over a specified period.
                    </p>
                </p>
                <p>
                    <b>No. of Occurrences :</b> Is the number of times a
                    particular error, alert, or data gap is observed or recorded
                    within a specified period.
                </p>
                <p>
                    It represents the frequency of the occurrence within the
                    dataset or time frame being analyzed.
                </p>
                <p>
                    <b>Availability Impact (%) : </b>
                    <p>
                        Refers to the extent to which the availability of a
                        system or service is affected by a particular error,
                        alert, or data gap, expressed as a percentage.
                    </p>
                    <p>
                        It quantifies the impact of the occurrence on the
                        overall availability of the system or service.
                    </p>
                </p>
                <p>
                    <b>Sites/Zones/Trackers Impacted : </b>
                    <p>
                        Is the number of individual sites/zones/trackers that
                        are affected or influenced by a particular error, alert,
                        or data gap.
                    </p>
                    <p>
                        It provides insight into the scope and reach of the
                        occurrence across different parts of the system or
                        network.
                    </p>
                </p>
                <p>
                    <b>Recommendation : </b>
                    <p>
                        Recommendation is the suggested actions or steps to be
                        taken in response to a particular error, alert, or data
                        gap.
                    </p>
                    <p>
                        It provides guidance on how to address the issue and
                        mitigate its impact on the site.
                    </p>
                </p>
            </div>
        ),
    },
    IssueDetail: {
        header: "Issue Detail",
        body: (
            <div>
                <p>
                    The "issue detail table" is a structured representation
                    containing comprehensive information about specific issues,
                    errors, or alerts and data gap
                </p>
                <p>
                    encountered within a Site/Zone/Trackers. It provides
                    detailed insights into each occurrence.
                </p>
            </div>
        ),
    },
};