import React from 'react';
import { AngleOverview, AvailabilityOverview, ErrorOverview, ModeOverview, UptimeOverview,EnergyOverview, MaintenanceOverview, WeathersOverview, RowsOverview} from './stats.overview';
import { CardStats as Card } from '../layout/Card';
import { usePageContext, energyMeterPage } from '../../context/page.context';
const SiteOverview = (props) =>{
  const pageContext = usePageContext();
  const pageName = pageContext.page;
  const energy_urls = [
    { name: "energy", url: "expected_actual", grouptype: "zones", slice: 999,path:"/energy", aggr: "avg" },
  ]


  const uptime_urls = [
    { name: "uptime", url: "rc_uptime_7days", grouptype: "zones", slice: 999 },
  ]
  const availability_urls = [
    { name: "availability", url: "rc_availability_7days", grouptype: "zones", slice: 999 },
  ]

  const error_urls = [
    // { name: "errorsum", url: "rc_error_sum", slice: 999 },
    // { name: "rcscount", url: "all_unique_rcs_count", slice: 999 },
    { name: "errordevices", url: "rc_error_stats", slice: 999, grouptype: "rcs" },

  ]
  const mode_urls = [
    // { name: "allmodes", url: "all_unique_modes"},
    // { name: "modemajoritytotal", url: "mode_majority_total"}
    {name: "modeData" ,url: "mode_overview_data"}
  ]
  const angle_urls = [
    { name: "rc_current_angle_1hr_avg", url: "rc_current_angle_1hr_avg"},
  ]
  const rows_urls = [
    {name: "tracker_offline_overview_data", url: "tracker_offline_overview_data",grouptype: "sites"}
  ]
  return(
    <>
    {props.priorityChart.length === 0 && pageName === energyMeterPage ? <EnergyOverview urls={energy_urls} /> : "" }
    {props.priorityChart.length === 0 ? <UptimeOverview urls={uptime_urls}/> : <Card>Loading Uptime Stats ...</Card>}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ? <AvailabilityOverview urls={availability_urls} /> : ""}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ? <AngleOverview urls={angle_urls} /> : ""}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ? <ErrorOverview urls={error_urls} /> : ""}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ? <WeathersOverview /> : ""}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ?  <ModeOverview urls={mode_urls} /> : ""}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ? <MaintenanceOverview /> : ""}
    {props.priorityChart.length === 0 && pageName !== energyMeterPage ?<RowsOverview urls={rows_urls} />: ""}

    </>
  );
}


export default SiteOverview;