import { toJS } from 'mobx';
import React, {useEffect, useState} from 'react';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import { CardStats as Card } from '../layout/Card';
import { ModeDomainToChart } from '../model/mode.data';
import { FetchData2 } from '../model/store.swr';
import { TooltipHelpContent } from '../pages/tooltip-help-content';
import { ZonesOfflineTable } from '../table/offlinezones';
import StatsOverview, { StatsOverviewPopup, StatsOverviewPopupOfflineTracker } from './statsChart';
import { getLevel, getLogData, getWeightedAvg } from '../../helpers/common';
import { GetFQURL } from '../model/store.swr'; 
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range' 
import { getStatsData } from '../../utils/apiCall';
import _, { max, set } from 'lodash'
import { useUserContext } from '../../context/user.context';
import { getSites } from '../../model/metadata';
import axios from 'axios';
import { domainsByCriteria } from '../layout/criteria';
import { useCriteriaContext } from '../../context/criteria.context';
import { Card4Opacity80 , CardSummary } from '../layout/Card';
import { InfoTooltip } from '../../model/info-tooltip';
import { usePageActionContext } from '../../context/page.context'
import ReactDOMServer from 'react-dom/server';
import TableContainer from '../table/TableContainer';
import { getWeatherIcon } from './weatherForecast';
import { isPortfolio, isSite } from '../layout/criteria';
import { useAvailabilityContext } from '../../context/availability.context';
import { WeatherTableContainer } from './weatherForecast';
import { Modal, Button, Table, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { FiDownload, FiArrowUp, FiArrowDown  } from "react-icons/fi";
import { BiSort } from "react-icons/bi";
import Select, { components } from 'react-select';
import './trackerofflineTable.css';
import { CSVLink } from 'react-csv';

export const getProgressValue = (value, value2) => {
    return (value / value2) * 100;
}

export const UptimeOverview = (props) => {
    const c = toJS(useStore().criteria)
    const userContext = useUserContext()
    const [criteria,setCriteria] = useState(c)
    const [tenants, setTenants] = useState([])
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-positional-accuracy-overview`,  usecase: `view ${level} positional accuracy overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    const availabilityContext = useAvailabilityContext()
    const activeZones = availabilityContext.activeZones

    var uptime_urls = [{ name: "uptime", url: "rc_uptime_7days", grouptype: "sites", slice: 999 }]

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.device !== c.device || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])

    useEffect(() => { // This effect work only for pf level
        
        if(criteria.site == ''){
            if(Array.isArray(tenants) && tenants.length > 1){
                uptime_urls = [{ name: "uptime", url: "rc_uptime_7days_combined", grouptype: "sites", slice: 999 }]
            }
            
            if(tenants.length){
                console.log("In Uptime card",uptime_urls)
                let url = GetFQURL(uptime_urls[0], criteria, 'json', false,[],null,null,null,logData)
                getStatsData(url,setResponse,setError)
            }
        }
        
        
    },[tenants,criteria.Ts, criteria.Te])

    useEffect(() => {
        if(criteria.site != ''){
            console.log("In Uptime card 1",uptime_urls)
            let url = GetFQURL(uptime_urls[0], criteria, 'json', false,[],null,null,null,logData)
            getStatsData(url,setResponse,setError)
        }
    },[criteria.site, criteria.zone, criteria.device, criteria.Ts, criteria.Te])
    // const response = FetchData2(useSWR, criteria, props.urls, 'json',logData)

    if (_.isEmpty(response)) return <Card>Loading Uptime Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Uptime Stats ...</Card>
    
    var uptimeArr = [...response]

    
    
    const uptime = uptimeArr[0].uptime;
    // const uptime = response[0].uptime;
    console.log("uptime....",uptime)

    const data = { suffix: uptime ? " %" : "", value: uptime ? uptime : 'No Data'  , timeperiod: 'Last 7 days', color: "#4daf4a", progress: uptime };

    return (<StatsOverview data={data} title="Positional Accuracy " tooltip={TooltipHelpContent.UptimeOverview} bar="true" type="uptime" toolTipPointer={'right'}/>)
}

export const AvailabilityOverview = (props) => {
    const c = toJS(useStore().criteria)
    const userContext = useUserContext()
    const [tenants, setTenants] = useState([])
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-tracker-availability-overview`,  usecase: `view ${level} tracker availability overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    const pageActionContext = usePageActionContext()


    const availabilityContext = useAvailabilityContext()
    const activeZones = availabilityContext.activeZones 
    var availability_urls = [{ name: "availability", url: "rc_availability_7days", grouptype: "sites", slice: 999 }]

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.device !== c.device || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])



    useEffect(() => { // This effect work only for pf level
        
        if(criteria.site == ''){
            if(Array.isArray(tenants) && tenants.length > 1){
                availability_urls = [{ name: "availability", url: "rc_availability_7days_combined", grouptype: "sites", slice: 999 }]
            }
            
            if(tenants.length){
                console.log("In availability card",availability_urls)
                let url = GetFQURL(availability_urls[0], criteria, 'json', false,[],null,null,null,logData)
                getStatsData(url,setResponse,setError)
            }
        }
        
        
    },[tenants,criteria.Ts, criteria.Te])

    useEffect(() => {
        if(criteria.site != ''){
            console.log("In availability card 1")
            const url = GetFQURL(availability_urls[0], criteria, 'json', false,[],null,null,null,logData)
            getStatsData(url,setResponse,setError)
        }
    },[criteria.site, criteria.zone, criteria.device, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Availability Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Availability Stats ...</Card>

    var availabilityArr = [...response]
    
    
    console.log("availabilityArr",criteria)
    const availability = availabilityArr[0].availability;
    const data = { suffix: availability ? " %" : "", value: availability ?  availability : 'No Data' , timeperiod: 'Last 7 days', color: "#4daf4a", progress: availability, info: availabilityContext.withMaintenance7day ? "Adjusted for maintenance activities" : "", infoclick:() => pageActionContext.setPage("Site Maintenance", [], false) };

    return (<StatsOverview data={data} title="Tracker Availability " tooltip={TooltipHelpContent.AvailabilityOverview} bar="true" type="availability" toolTipPointer={'right'} />)
}

export const MaintenanceOverview = props => {

    var c = toJS(useStore().criteria)
    const [criteria, setCriteria] = useState(c)
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-maintenance-overview`,  usecase: `view ${level} maintenance overview`, category: 'overview', level: level})
    
    const pageActionContext = usePageActionContext()


    const availabilityContext = useAvailabilityContext()
    const {  maintenanceActivities, maintenanceError } = availabilityContext

    // if (_.isEmpty(response)) return <Card>Loading Maintenance Stats ...</Card>
    if (maintenanceError) return <Card>Error In Loading Maintenance Stats ...</Card>

    const data = { 
        suffix: " activities ", 
        value: maintenanceActivities,
        // timeperiod: "Past 60 minutes", 
        color: "red", 
        // value2: totaldevices, 
        // progress: getProgressValue(errorDeviceCount, totaldevices) 
        // action: {
        //     text: "Adjust Availability",
        //     description: "Adjust availability to accomodate maintenance activities if trackers were operational during the activity",
        //     revert: () => setWithMaintenance( false ),
        //     apply: () => setWithMaintenance(true),
        // }
    };

    return (maintenanceActivities === 0 ? null : <StatsOverview data={data} title="Maintenance Activities" tooltip={TooltipHelpContent.MaintenanceOverview} bar="true" type="ratio" toolTipPointer={'left'}  onClick={() => pageActionContext.setPage("Site Maintenance", [], false)} />)
}

export const ErrorOverview = (props) => {

    var c = toJS(useStore().criteria)
    const [criteria, setCriteria] = useState(c)
    const userContext = useUserContext()
    const [tenants, setTenants] = useState([])
    // criteria = {pf: criteria.pf, site: criteria.site, zone: criteria.zone, Ts: criteria.Ts, Te: criteria.Te}
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-tracker-with-errors-overview`,  usecase: `view ${level} tracker with errors overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    var error_urls = [{ name: "errordevices", url: "rc_error_stats", slice: 999, grouptype: "rcs" }]

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])

    useEffect(() => { // This effect work only for pf level
        
        if(criteria.site == ''){
            if(Array.isArray(tenants) && tenants.length > 1){
                error_urls = [{ name: "errordevices", url: "rc_error_stats_combined", slice: 999, grouptype: "rcs" }]
            }
            
            if(tenants.length){
                console.log("In errors card",error_urls)
                let url = GetFQURL(error_urls[0], criteria, 'json', false,[],null,null,null,logData)
                getStatsData(url,setResponse,setError)
            }
        }
        
        
    },[tenants,criteria.Ts, criteria.Te])

    useEffect(() => {
        if(criteria.site != ''){
            console.log("In errors card 1")
            const url = GetFQURL(error_urls[0], criteria, 'json', false,[],null,null,null,logData)
            getStatsData(url,setResponse,setError)
        }
    },[criteria.site, criteria.zone, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Error Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Error Stats ...</Card>


    var errorCount  = 0
    var errorDeviceCount = 0
    var totaldevices = 0 
    
    if (response && response.length>0) {
        errorDeviceCount = response.find(f => f.name === "error_devices")["value"]
        totaldevices = response.find(f => f.name === "total_devices")["value"]
    }



    const data = { 
        suffix: " RCs ", 
        value:  errorDeviceCount ,
        timeperiod: "Past 60 minutes", 
        color: "red", 
        value2: totaldevices, 
        progress: getProgressValue(errorDeviceCount, totaldevices) 
    };

    return (<StatsOverview data={data} title="Trackers with Errors" tooltip={TooltipHelpContent.RCsWithErrors} bar="true" type="ratio" toolTipPointer={'left'} />)
}

export const ZonesOverview = (props) => {


    const c = toJS(useStore().criteria)
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-zones-communicating-overview`,  usecase: `view ${level} zones communicating overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        const url = GetFQURL(props.urls[0], criteria, 'json', false,[],null,null,null,logData)
        getStatsData(url,setResponse,setError)
    },[criteria.pf, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Zones Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Zones Stats ...</Card>

    const responseData = Array.isArray(response) ? response[0] : response.data
    const zonesCommObj = responseData.zonesComm[0];
    const zonesCount = responseData.zones.length;
    const zonesOffline = responseData.zonesoffline;
    const data = { suffix: " zones ", value: zonesCommObj.totalZones , timeperiod: "Past 30 minutes", color: "#4daf4a", value2: zonesCount, progress: getProgressValue(zonesCommObj.totalZones, zonesCount) };
    return (<StatsOverviewPopup data={data} title="Zones Communicating"  toolTipPointer={'right'} tooltip={TooltipHelpContent.ZonesOverview} popChild={ZonesOfflineTable} popData={{data: zonesOffline}}/>)
}

export const AngleOverview = (props) => {
    const c = toJS(useStore().criteria)
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-average-angle-overview`,  usecase: `view ${level} average angle overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        const url = GetFQURL(props.urls[0], criteria, 'json', false,[],null,null,null,logData)
        getStatsData(url,setResponse,setError)
    },[criteria.site, criteria.zone, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Current Angle ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Error Stats ...</Card>

    // if (!response.isReady) return <Card>Loading Current Angle ... </Card>
    
    if(response && response.length){
        const rc_avg_angle = response[0].value === null ? 'NA' : response[0].value.toFixed(2)+ ' º'
        const data = { "value": rc_avg_angle , "timeperiod": "Past 60 minutes", "color": "#4daf4a" };
        return (<StatsOverview data={data} title="Average Angle​" tooltip={TooltipHelpContent.AverageAngle} />)
    }
}

export const ModeOverview = (props) => {


    const c = toJS(useStore().criteria)
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    let logData = getLogData(criteria, {featureId: `view-${level}-mode-overview`,  usecase: `view ${level} mode overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    function filter(data){
        return true
    }

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        const url = GetFQURL(props.urls[0], criteria, 'json', false,[],null,null,null, logData)
        getStatsData(url,setResponse,setError)
    },[criteria.site, criteria.zone, criteria.Ts, criteria.Te])


    if (_.isEmpty(response)) return <Card>Loading Mode overview ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Mode overview ...</Card>


    
    const responseData = Array.isArray(response) ? response[0] : response.data
    
    if(responseData.allmodes){
        const dps = ModeDomainToChart(responseData.modemajoritytotal, responseData.allmodes, filter)
        if (dps.datasets.length != 0){
            const modetype = dps.datasets.reduce((a,b)=>parseFloat(a.data[0])>parseFloat(b.data[0])? a:b)
            const ModeOverview = {"value": modetype.label, "timeperiod":"Past 60 minutes", progress: modetype.data[0]};
            return (<StatsOverview  data={ModeOverview} title="Mode" tooltip={TooltipHelpContent.CurrentMode} />)
        } 
    }
    return <div></div>
    
}

export const EnergyOverview = (props) => {
    const criteria = toJS(useStore().criteria)
    const response = FetchData2(useSWR, criteria, props.urls)

    if (!response.isReady) return <Card>Loading Energy Stats ...</Card>

    const energyobj = response.data.energy;
    var totalExpected = 0;
    var totalActual = 0;
    var progressValue = 0;
    var totalDataCount = ""
    if(energyobj && energyobj.length > 0 ){
        energyobj.forEach(element => {
            totalActual = totalActual + element.actual_power;
            totalExpected = totalExpected + element.expected_power;
        });
        totalActual = totalActual.toFixed(2);
        totalExpected = totalExpected.toFixed(2);
        progressValue = (totalActual/totalExpected) * 100;
    }
    //(energyobj && energyobj.length > 0 && uptimeobj[0].uptime) ? uptimeobj[0].uptime : 0

    const data = { suffix: " MWh", value: totalActual , value2:totalExpected, timeperiod: '', color: "#4daf4a", progress: progressValue,totalDataCount:(energyobj.length + (criteria.site === ""?" Sites":" Inverters")) };

    return (
        <StatsOverview data={data} title="Energy Produced " tooltip={TooltipHelpContent.EnergyProduced} bar="true" type="energy"/>
    )
}

export const WeathersOverview = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [forecastData, setForecastData] = useState(null);
    const [windgustData, setWindgustData] = useState(null);
    const [error, setError] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const criteriaContext = useCriteriaContext();
    const criteria = useCriteriaContext();
    const domainArray = domainsByCriteria(criteria);
    const siteValueIndex = domainArray.indexOf('site');
    const siteValue = siteValueIndex !== -1 ? criteria.site : null;
    const [showTable, setShowTable] = useState(false);
      

    useEffect(() => {
        const initializeValuesFromSites = () => {
            setIsReady(false); // Set loading state to false before fetching data
            getSites(criteriaContext, (data) => {
                if (data && data.length > 0) {
                    const selectedSite = data.find(site => site.site_id === siteValue);
                    console.log("selectedSite", selectedSite);
                    if (selectedSite) {
                        const { latitude, longitude } = selectedSite;
                        setLatitude(latitude);
                        setLongitude(longitude);
                        setIsReady(true);
                        // Fetch weather data once latitude and longitude are set
                        FetchWeather(latitude, longitude);
                    } else {
                        console.error(`Site with value ${siteValue} not found`);
                    }
                } 
            }, () => {});
        };
        initializeValuesFromSites();
    
        async function FetchWeather(latitude, longitude, retryCount = 0) {
            if (retryCount >= 3) {
                setError("Weather forecast data is not available");
                return;
            }
            if (latitude !== null && longitude !== null) {
                try {
                    const nwsResponse = await axios.get(`https://api.weather.gov/points/${latitude},${longitude}`);
                    const forecastUrl = nwsResponse.data.properties.forecast;
                    const headers = {
                        "Feature-Flags": "forecast_wind_speed_qv"
                    };
                    const forecastResponse = await axios.get(forecastUrl,{headers});
                    const period=forecastResponse.data.properties.periods[0];
                    setForecastData(forecastResponse.data.properties.periods);
                   
                    

                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 500) {
                            // If 500 error occurs, retry fetching after 5 seconds
                            console.error("Error fetching weather data. Retrying in 5 seconds...", error.response.status);
                            await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds
                            FetchWeather(latitude, longitude, retryCount +1); // Retry fetching
                        } else if (error.response.status === 404) {
                            setError("Weather forecast is not available for this location");
                        } else {
                            setError("Weather forecast data is not available");
                        }
                    } else {
                        setError("Weather forecast data is not available");
                    }
                }
            } else {
                setError("Forecast is unavailable as latitude and longitude data are not available for the site.");
            }
        }   
        setError("");
    }, [siteValue]);
    
    if (!isReady) return <Card>Loading Weather Forecast ...</Card>;
    if (error) return <Card>{error}</Card>;
    if (!forecastData) return <Card>Weather forecast data is not  available</Card>;

    const tooltipContent = TooltipHelpContent.WeatherOverview
    const tooltip = (
        <div>
            <h5>{tooltipContent.header}</h5>
            {tooltipContent.body}
        </div>
    )

    const handleClick = (e) => {
        e.preventDefault();
        setShowTable((prevShowTable) => !prevShowTable);
    }
    
    const handleTableHeaderClick = (e) => {
        e.stopPropagation(); 
      };

    const columns = [
        { Header: "Day",accessor: "name"}, { Header: "Temperature (°F)",accessor: "temperature"}, { Header: "Short Forecast",accessor: "shortForecast"}, { Header: "Wind Speed(Min/Max)",accessor: "windSpeed"}, { Header: "Wind Gust",accessor: "windGust"},{ Header: "Precipitation (%)",accessor: "probabilityOfPrecipitation"}
    ];

    const holidays = ["New Year's Day", "Memorial Day", "Independence Day", "Labor Day", "Thanksgiving", "Christmas", "Martin Luther King Jr. Day", "Washington's Birthday", "Juneteenth", "Columbus Day", "Veterans Day"];

    const regularDays = ["Monday", "Monday Night", "Tuesday", "Tuesday Night", "Wednesday", "Wednesday Night", "Thursday", "Thursday Night", "Friday", "Friday Night", "Saturday", "Saturday Night", "Sunday", "Sunday Night", "Monday", "Monday Night"];
 
    const weatherObj1 = forecastData.map((forecast, index, array) => {
        let updatedName = forecast.name;
        //let  windgust=forecast.windgust || "No data";
        // Condition: Replace previous holiday with previous regular day from next forecast
        for (let i = 0; i < holidays.length; i++) {
            if (forecast.name.includes(holidays[i]) && index < array.length - 1) {
                const nextName = array[index + 1].name;
                const nextRegularDayIndex = regularDays.indexOf(nextName);
                if (nextRegularDayIndex >= 1) {
                    const prevRegularDay = regularDays[nextRegularDayIndex - 1];
                    updatedName = prevRegularDay + ' ' + forecast.name.substring(forecast.name.indexOf(holidays[i]) + holidays[i].length);
                } else {
                    updatedName = 'Today'; // If no previous regular day, fallback to 'Today'
                }
                break;
            }
        }  
        // Handle wind speed value
    let windSpeedValue;
    if (forecast.windSpeed.value !== undefined) {
        windSpeedValue = Math.round(forecast.windSpeed.value * 0.621371) + ' mph'; // Convert km/h to mph
    } else if (forecast.windSpeed.minValue !== undefined && forecast.windSpeed.maxValue !== undefined) {
        windSpeedValue = Math.round(forecast.windSpeed.minValue * 0.621371)+ ' to ' + Math.round(forecast.windSpeed.maxValue * 0.621371) + ' mph';
    } else {
        windSpeedValue = "--";
    }

    // Handle wind gust value
    let windGustValue;
    if (forecast.windGust && forecast.windGust.value !== undefined) {
        windGustValue = Math.round(forecast.windGust.value * 0.621371) + ' mph'; // Convert km/h to mph
    } else {
        windGustValue = "";
    }
    return {
        name: updatedName,
        temperature: forecast.temperature,
        shortForecast: forecast.shortForecast,
        windSpeed: windSpeedValue,
        probabilityOfPrecipitation: forecast.probabilityOfPrecipitation.value,
        windGust: windGustValue
            
        };
    });
    

    const weatherIcon = getWeatherIcon(weatherObj1[0].shortForecast);
    const displayForecast = weatherObj1[0].shortForecast;
    const splitForecast = displayForecast.split(/(?:\s+then\s+|\s+And\s+)/)[0];
    //const finalwindgustvalue = finalgustvalue
    const windGust = weatherObj1[0].windGust === "" ? "--" : weatherObj1[0].windGust;

    return (
        <CardSummary>
            <div className="popup" style={{ position: 'relative' }}>
                <div className="card-body">

                    <h5 className="card-title" style={{marginBottom:'4px'}}><span onClick={handleClick}>Weather Forecast <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} /></span></h5>
                    <div className="text-white h6 card-text" style={{fontSize:'13px',marginBottom:'4px'}}><span onClick={handleClick}>{weatherObj1[0].temperature} °F</span></div>
                    <div className="text-white h6 card-text" style={{fontSize: '13px',marginBottom:'4px'}}><span onClick={handleClick}>{splitForecast}</span></div>
                    <div className="text-white h6 card-text" style={{fontSize:'13px',marginBottom:'4px'}}><span onClick={handleClick}>Wind Speed : {weatherObj1[0].windSpeed}</span></div>
                    <div className="text-white h6 card-text" style={{fontSize:'13px',marginBottom:'4px'}}><span onClick={handleClick}>Wind Gust : {windGust} </span></div>
                   </div>

                <span className={showTable ? "popuptext show" : "popuptext"} id="myPopup" onClick={handleClick}>
                    <div className="col-sm-12 no-gutters">
                        <Card4Opacity80>
                        <div className="mb-3 popup-header" onClick={(e) => handleTableHeaderClick(e)}>
                                <b className="mb-3" >Detailed Forecast For Next 7 Days</b>
                            </div>
                            <div className="d-flex align-items-center mb-1">
                                <WeatherTableContainer
                                    columns={columns}
                                    data={weatherObj1}
                                    />
                            </div>
                        </Card4Opacity80>
                    </div>
                </span>
            </div>
            <div className="stats-desc text-right py-2 px-2 " style={{  "position": "absolute", "top": "0", "right": "0" }}>{weatherIcon}</div>
            <div className="stats-desc text-right py-2 px-2 " style={{ "position": "absolute", "bottom": "0", "right": "0", "cursor": "pointer" }}>
                <span onClick={handleClick}>Next 7 Days <span className="fas fa-angle-double-down "></span></span>
            </div>
        </CardSummary>
    );    
};

// function to convert seconds to minutes/hours
const formatTime = (seconds) => {
    const totalMinutes = Math.floor(seconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
  
    if (days > 0) {
      return `${days}d ${hours}h`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };

const DotOption = (props) => {
  const { selectProps, data, label } = props;
  // Convert comma-separated strings to arrays
  const ZonesOffline = selectProps.ZonesOffline ? selectProps.ZonesOffline.split(',') : [];
  const SitesOffline = selectProps.SitesOffline ? selectProps.SitesOffline.split(',') : [];
 // Determine if the site or zone is not communicating
  const isSiteNotCommunicating = SitesOffline.includes(data.value);
  const isZoneNotCommunicating = ZonesOffline.includes(data.value);
  // Set dot color based on communication status
  const dotColor = isSiteNotCommunicating || isZoneNotCommunicating ? 'red' : 'green';

  return (
    <components.Option {...props}>
      <span
        style={{
          display: 'inline-block',
          width: '8px',
          height: '8px',
          backgroundColor: dotColor,
          borderRadius: '50%',
          marginRight: '8px',
        }}
      />
      {label}
    </components.Option>
  );
};

const InfoModal = ({
    show,
    handleClose,
    title,
    data,
    alert_data,
    selectedSite = [],
    setSelectedSite,
    selectedZone = [],
    setSelectedZone,
    criteria
  }) => {
    // Sorting state
     const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  
    // Mapping of fault codes to Alert names
     const faultDescriptions = {
        0: "NO_FAULT",
        1: "Over_Current_Fault",
        2: "Minimum_Battery_Voltage_fault",
        4: "Board_Temperature_Exceed_fault",
        6: "Motor_Stall_Fault",
        7: "Zigbee_Fault",
        8: "Communication_Fault",
        9: "Inclinometer_Fault",
        10: "SPI_Flash_Fault",
        11: "OTA_Fault",
        12: "EEPROM_Fault",
        13: "RTC_Failure",
        14: "Unknown_Fault",
        15: "Locking_System_Fault",
        16: "Locked_Moved_Fault",
        17: "Low_Battery_Stow_Fault",
        33: "ZC_Blocked_State",
        34: "Set_Command_Flag_Enabled",
        35: "Mechanical_Overload_Occurred",
    };
  
    const getAlertForTracker = (device_id, site_id, zone_id) => {
      const alert = alert_data.find(
        (alert) =>
          alert.device_id === device_id &&
          alert.site_id === site_id &&
          alert.zone_id === zone_id
      );
      if (alert) {
        const descriptiveAlerts = alert.alert.map(
          (code) => faultDescriptions[code] || ``
        );
        return descriptiveAlerts.join(", ");
      }
      return " ";
    };
  
    // Create options for Select components
    const siteOptions = Array.from(
      new Set(data.map((tracker) => tracker.site_id))
     ).map((site_id) => ({ value: site_id, label: site_id  }));
    const offlineSites =  data.map((tracker) => tracker.site_not_communicating)[0]
    const offlineZones =  data.map((tracker) => tracker.zone_not_communicating)[0]
    const handleSiteChange = (selectedOptions) => {
      setSelectedSite(selectedOptions || []);
      setSelectedZone([]); // Reset selected zones when sites change
    };
  
    const handleZoneChange = (selectedOptions) => {
      setSelectedZone(selectedOptions || []);
    };

    // create options for zone
    const zoneOptions = Array.from(
      new Set(data.map((tracker) => tracker.zone_id))
    ).map((zone_id) => ({ value: zone_id, label: zone_id }));

    // Filter data based on selected sites and zones
    const filteredData = data.filter((tracker) => {
      return (
        (!selectedSite ||
          selectedSite.length === 0 ||
          selectedSite.some((site) => site.value === tracker.site_id)) &&
        (!selectedZone ||
          selectedZone.length === 0 ||
          selectedZone.some((zone) => zone.value === tracker.zone_id))
      );
    });
  
    // Sorting logic
    const sortedData = filteredData.sort((a, b) => {
      if (sortConfig.key) {
        const aKey = a[sortConfig.key];
        const bKey = b[sortConfig.key];
  
        if (aKey < bKey) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
      }
      return 0;
    });
  
    const handleSort = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    //  filename construction
    const filename = `Offline_trackers_${
      Array.isArray(selectedSite) && selectedSite.length
        ? selectedSite.map((site) => site.value).join("_")
        : criteria.pf
    }_${
      Array.isArray(selectedZone) && selectedZone.length
        ? `${criteria.site}_${selectedZone.map((zone) => zone.value).join("_")}`
        : criteria.site
    }_${new Date().toISOString().split("T")[0]}.csv`;
  
    // Prepare CSV data
    const csvData = sortedData.map((tracker) => ({
      Site_ID: tracker.site_id,
      Zone_ID: tracker.zone_id,
      Device_ID: tracker.device_id,
      Row_ID: tracker.tracker_id,
      Last_Reported: formatTime(tracker.last_reportedon),
      Last_Reported_Alert: getAlertForTracker(
        tracker.device_id,
        tracker.site_id,
        tracker.zone_id
      ),
    }));
  
    return (
      <Modal
        show={show}
        onHide={handleClose}
        // style={{ maxHeight: "250vh", height: "90vh" }}
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} 
      >
        <Modal.Header closeButton>
          <div
            style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
          >
            <Modal.Title>{title}</Modal.Title>
            <div>
              <CSVLink 
                data={csvData}
                filename={filename}
                style={{
                  color: "black",
                  padding: "5px",
                }}
              >
                <FiDownload />
              </CSVLink>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="tracker_offline-info-modal-body">
          <div className="tracker_offline-table-responsive-container">
            <div className="dropdown-container" style={{ marginBottom: "20px" }}>
              {criteria.pf && criteria.site === "" && (
                <div style={{ marginRight: "10px" }}>
                  <Select
                    options={siteOptions}
                    value={selectedSite}
                    isMulti={true}
                    onChange={handleSiteChange}
                    placeholder="Select Site(s)"
                    isClearable
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    SitesOffline = {offlineSites}
                    components={{ Option: DotOption  }}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 1050 }),
                      menuList: (provided) => ({
                        ...provided,
                        overflowY: "auto", // Enable vertical scrolling
                      }),
                    }}
                  />
                </div>
              )}
              {criteria.pf && criteria.site && (
                <div style={{ marginRight: "10px" }}>
                  <Select
                    options={zoneOptions}
                    value={selectedZone}
                    onChange={handleZoneChange}
                    placeholder="Select Zone(s)"
                    isMulti={true}
                    isClearable
                    closeMenuOnSelect={false}
                    ZonesOffline = {offlineZones}
                    components={{ Option: DotOption }}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 1050 }),
                      menuList: (provided) => ({
                        ...provided,
                        overflowY: "auto", // Enable vertical scrolling
                      }),
                    }}
                  />
                </div>
              )}
            </div>
            <Table striped bordered hover className="tracker_offline-table">
              <thead className="tracker_offline-table-header" >
                <tr>
                  <th
                    className="tracker_offline-table-th sortable"
                    style={{ textAlign: "left" , paddingLeft: "0px"}}
                    onClick={() => handleSort('site_id')}
                  >
                    Site ID
                    {sortConfig.key === 'site_id' ? (
                      sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />
                    ) : (
                      <BiSort />
                    )}
                  </th>
                  <th
                    className="tracker_offline-table-th sortable"
                    onClick={() => handleSort('zone_id')}
                  >
                    Zone ID
                    {sortConfig.key === 'zone_id' ? (
                      sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />
                    ) : (
                      <BiSort />
                    )}
                  </th>
                  <th

                    className="tracker_offline-table-th sortable"
                    style={{ textAlign: "left" , paddingLeft: "0px", border: "none"}}
                    onClick={() => handleSort('device_id')}
                  >
                    Device ID
                    {sortConfig.key === 'device_id' ? (
                      sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />
                    ) : (
                      <BiSort />
                    )}
                  </th>
                  <th

                    className="tracker_offline-table-th sortable"
                    onClick={() => handleSort('tracker_id')}
                  >
                    Row ID
                    {sortConfig.key === 'tracker_id' ? (
                      sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />
                    ) : (
                      <BiSort />
                    )}
                  </th>
                  <th

                    className="tracker_offline-table-th sortable"
                    onClick={() => handleSort('last_reportedon')}
                  >
                    Last Reported
                    {sortConfig.key === 'last_reportedon' ? (
                      sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />
                    ) : (
                      <BiSort />
                    )}
                  </th>
                  <th

                    className="tracker_offline-table-th sortable"
                    onClick={() => handleSort('last_reported_alert')}
                  >
                    Last Reported Alert
                  </th>
                </tr>
              </thead>
              <tbody className="tracker_offline-table-body" >
                {sortedData.map((tracker, index) => (
                  <tr key={index}>
                    <td style={{ paddingLeft:"10px" }}>{tracker.site_id}</td>
                    <td style={{ paddingLeft:"15px" }}>{tracker.zone_id}</td>
                    <td style={{ paddingLeft:"15px" }}>{tracker.device_id}</td>
                    <td style={{ paddingLeft:"20px" }}>{tracker.tracker_id}</td>
                    <td style={{ paddingLeft:"20px" }}>{formatTime(tracker.last_reportedon)}</td>
                    <td style={{ paddingLeft:"20px"  }}>{getAlertForTracker(tracker.device_id, tracker.site_id, tracker.zone_id)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
        </Modal.Body>
      </Modal>
    );
  };
  

export const RowsOverview = (props) => {
    const c = toJS(useStore().criteria);
  
    const [criteria, setCriteria] = useState(c);
    const level = getLevel(useStore().criteria);
    const logData = getLogData(criteria, {
        featureId: `view-${level}-rows-communicating-overview`,
        usecase: `view ${level} rows communicating overview`,
        category: 'overview',
        level: level,
    });
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', content: '' });
    const [loading, setLoading] = useState(true);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null); 

    const fetchLatestTimestamp = () => {
      return new Date().toISOString();  // Get the current timestamp as an example
    };

    const handleOpenModal = () => {
        // Open the modal only if status is 'True' and trackeroffline is not empty
        if (responseData && responseData.status === 'True' && responseData.trackeroffline.length > 0) {
            setModalContent({
                title: 'Offline Trackers',
            });
            setShowModal(true);
        }
    };

    // const handleCloseModal = () => setShowModal(false);
    const handleCloseModal = () => {
        setSelectedZone(null); // Reset selectedZone when closing the modal
        setSelectedSite(null); // Reset selectedSite when closing the modal
        setShowModal(false);   // Close the modal
      };

    useEffect(() => {
        if ( 
            criteria.pf !== c.pf ||
            criteria.site !== c.site ||
            criteria.zone !== c.zone ||
            criteria.Ts !== c.Ts ||
            criteria.Te !== c.Te
        ) {
            setCriteria(c);
        }
    }, [c]);

    const fetchData = () => {
      const latestTimestamp = fetchLatestTimestamp();  
      const url = GetFQURL(props.urls[0], { ...criteria, latestTimestamp }, 'json', null, [], null, null, null, logData);
      setLoading(true);
      setError(null);

      getStatsData(
          url,
          (data) => {
              setResponse(data);
              setLoading(false);
          },
          (err) => {
              setError(err);
              setLoading(false);
          }
      );
  };
  
  // Fetch data when criteria change
  useEffect(() => {
      if (
          criteria.pf !== c.pf ||
          criteria.site !== c.site ||
          criteria.zone !== c.zone ||
          criteria.Ts !== c.Ts ||
          criteria.Te !== c.Te
      ) {
          setCriteria(c);
      }
  }, [c]);

  useEffect(() => {
      fetchData();
  }, [criteria.pf, criteria.site, criteria.Ts, criteria.Te]);

//   const handleRefreshClick = () => {
//     fetchData();
// };


    // Render loading state
    if (loading) return <Card>Loading Tracker Data...</Card>;
    if (error) return <Card>Error In Loading Tracker Stats...</Card>;

    // Ensure responseData is an object
    const responseData = Array.isArray(response) ? response[0] : response && response.data;
    console.log('responseData', responseData);

    let total_zones;
    const trackerOfflineZoneData = responseData.tracker_offline_zone_data;
    trackerOfflineZoneData.forEach((item) => {
      total_zones = item.total_zones;
    });
    const trackerofflinearray = responseData.trackeroffline;
    let zoneArrayLength;

    if (trackerofflinearray) {
        const firstItem = trackerofflinearray[0];
        if (firstItem && firstItem.zone_not_communicating) {
            const zoneNotCommunicating = firstItem.zone_not_communicating;
            const zoneArray = zoneNotCommunicating.split(',');
            zoneArrayLength = zoneArray.length;
        }
    } else {
        // Handle the case where trackerofflinearray is null or undefined
        zoneArrayLength = 0;
    }

 
    if (!responseData || responseData.status !== 'True' || !responseData.trackeroffline || responseData.trackeroffline.length === 0) {
        return <Card className>No Trackers are offline </Card>;
    }

    // access trackeroffline properties
    const trackeroffline = responseData.trackeroffline[0] || {};
    const offline_tracker_count = trackeroffline.offline_trackers || 0;
    const total_tracker_count = trackeroffline.total_devices
    const percentage_offline = Math.round((offline_tracker_count / total_tracker_count) * 100);
    
    const data = {
        suffix: ' RCs ',
        value: offline_tracker_count,
        timeperiod: 'Past 60 minutes',
        color: '#4daf4a',
        value2: total_tracker_count,
        progress: getProgressValue(offline_tracker_count, total_tracker_count),
        percentage_offline: percentage_offline,
        value3: total_zones,
        value4: zoneArrayLength,
    };

    return (
        <>
            <StatsOverviewPopupOfflineTracker
                data={data}
                title="Trackers not communicating"
                toolTipPointer={'bottom'}
                tooltip={TooltipHelpContent.RowsOverview}
                onClick={handleOpenModal}
                // onRefreshClick={handleRefreshClick}
            />
            {responseData && responseData.status === 'True' && responseData.trackeroffline.length > 0 && (
                <InfoModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    title={modalContent.title}
                    content={modalContent.content}
                    data_status={responseData.status}
                    data={responseData.trackeroffline}
                    alert_data={responseData.trackeroffline_alert}
                    selectedSite={selectedSite} // Pass selectedSite
                    setSelectedSite={setSelectedSite} // Pass setSelectedSite
                    selectedZone={selectedZone} // Pass selectedZone
                    setSelectedZone={setSelectedZone} // Pass setSelectedZone
                    criteria={criteria}
                />
            )}
        </>
    );
};


