import React from "react";

export const issueSummaryColumns = [
    {
        dataField: "issue",
        text: "Issue",
        selected: true,
        formatter: (cell) => (
            <>
                <div
                    style={{
                        whiteSpace: "normal",
                        wordWarp: "break-word",
                    }}
                >
                    {cell}
                </div>
            </>
        ),
    },
    {
        dataField: "avg_secs",
        text: "Avg. Duration ",
        selected: true,
    },
    {
        dataField: "max_secs",
        text: "Max. Duration ",
        selected: true,
    },
    {
        dataField: "occurrences",
        text: "No. of Occurrence(s)",
        selected: true,
    },
    {
        dataField: "ava_impact",
        text: "Availability Impact (%)",
        selected: true,
    },
    {
        dataField: "devices_with_issue",
        text: "Trackers Impacted",
        selected: false,
    },
    { 
        dataField: "total_devices", 
        text: "Total Devices", 
        selected: false, 
    },
    {
        dataField: "rec",
        text: "Recommendation",
        selected: true,
    },
];

export const issueDetailColumns = [
    {
        dataField: "row_id",
        text: "Row ID",
        selected: true,
    },
    {
        dataField: "site_id",
        text: "Site ID",
        selected: true,
    },
    {
        dataField: "zone_id",
        text: "Zone ID",
        selected: true,
    },
    {
        dataField: "device_id",
        text: "Tracker ID",
        selected: true,
    },
    {
        dataField: "avg_secs",
        text: "Avg. Duration ",
        selected: true,
    },
    {
        dataField: "max_secs",
        text: "Max. Duration ",
        selected: true,
    },
    {
        dataField: "occurrences",
        text: "Occurrence(s)",
        selected: true,
    },
    {
        dataField: "devices_with_issue",
        text: "Trackers With Issue",
        selected: false,
    },
    {
        dataField: "available_devices",
        text: "Total Trackers",
        selected: false,
    },
    {
        dataField: "start_time",
        text: "Start Time",
        selected: false,
    },
    {
        dataField: "end_time",
        text: "End Time",
        selected: false,
    },
    {
        dataField: "duration",
        text: "Duration(s) ",
        selected: false,
    },
];


export const deviceIssueSummaryColumns = [
    {
        dataField: "issue",
        text: "Issue",
        selected: true,
        formatter: (cell) => (
            <>
                <div
                    style={{
                        whiteSpace: "normal",
                        wordWarp: "break-word",
                    }}
                >
                    {cell}
                </div>
            </>
        ),
    },
    {
        dataField: "avg_secs",
        text: "Avg. Duration",
        selected: true,
    },
    {
        dataField: "max_secs",
        text: "Max. Duration ",
        selected: true,
    },
    {
        dataField: "occurrences",
        text: "No. of Occurrence(s)",
        selected: true,
    },
    {
        dataField: "ava_impact",
        text: "Availability Impact (%)",
        selected: true,
    },
    {
        dataField: "rec",
        text: "Recommendation",
        selected: true,
    },
];

export const deviceIssueDetailColumns = [
    {
        dataField: "start_time",
        text: "Start Time",
        selected: true,
    },
    {
        dataField: "end_time",
        text: "End Time",
        selected: true,
    },
    {
        dataField: "duration",
        text: "Duration(s) ",
        selected: true,
    },
];
