import React, { useState, useMemo } from 'react';
import styled from "styled-components";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Modal from 'react-bootstrap/Modal';
import { useStoreActionContext } from '../../context/store.context';
import { useDataviewActionContext } from '../../context/dataview.context';
import { errorCodes } from '../modelviewer/heatmap';
import {
    DownloadCsv_Issue_Detail_table,
    paginationOptions,
    sortColumn,
    showArrow,
} from "./issueIdentificationUtils";
import { InfoTooltip } from '../../model/info-tooltip';
import ReactDOMServer from 'react-dom/server';
import { TooltipContent } from './TooltipContent';

const Styles = styled.div`
thead tr:nth-child(1) th {
background: rgba(240, 242, 244, 1);
position: sticky;
top: 0;

.table thead th:first-child,
.table tbody td:first-child {
position: sticky;
left: 0;
background-color: rgba(240, 242, 244, 1);
`;

const Table = React.memo(({data, rowAttrbs, childData, detailTableColumns, level, fileName, criteria}) => {
    const storeAction = useStoreActionContext();
    const dataViewAction = useDataviewActionContext()
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedData, setSelectedData] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [sortedRow, setSortedRow] = useState(
        detailTableColumns.map((t) => ({
            ...t,
            sorted: t.dataField === "start_time" ? "asc" : null,
        })));
    const [dtRowAttrbs, setDtRowAttrbs] = useState(detailTableColumns);
    const [selectedValue, setSelectedValue] = useState("Zone ID");
    const tooltipInfo = TooltipContent.IssueDetail ? (
        <div>
            <h5>{TooltipContent.IssueDetail.header}</h5>
            <p>{TooltipContent.IssueDetail.body}</p>
        </div>
    ) : (
        ""
    );

    const memoizedData = useMemo(() => data, [data]);
    const memoizedChildData = useMemo(() => childData, [childData]);
    const memoizedRowAttrbs = useMemo(() => rowAttrbs, [rowAttrbs]);
    const memoizedDetailTableColumns = useMemo(() => detailTableColumns, [detailTableColumns]);

    const handleCellClick = (e, cell, row, rowIndex) => {
        console.log("cell", cell)
        setSelectedValue("Zone ID")
        detailTableColumns.map((t) => ({
            ...t,
            sorted: t.dataField === "start_time" ? "asc" : null,
        }));
        // const issueValue = cell.issue === "No Data" ? "no_data" : cell.issue === "Outage Data" ? "zone_no_data" : cell.issue === "Outages Data" ? "site_no_data" : cell.issue === "Devices With No Data" || cell.issue === "Zones With No Data" ? "partial_nodata" : Object.keys(errorCodes).find(
        //     (key) => errorCodes[key] === cell.issue
        // );
        let issueValue = null;
        if (level === 'rc') {
            issueValue = cell.issue === "No Tracker Data" ? "no_data" : Object.keys(errorCodes).find(
            (key) => errorCodes[key] === cell.issue);
        } else if (level === "zone") {
            issueValue = cell.issue === "No Zone Data" ? "zone_no_data" : cell.issue === "Trackers With No Data" ? "partial_nodata" : Object.keys(errorCodes).find(
            (key) => errorCodes[key] === cell.issue);
        } else if (level === "site") {
            issueValue = cell.issue === "No Site Data" ? "site_no_data": cell.issue === "Zones With No Data" ? "partial_nodata" : cell.issue === "Tracker With No Data" ? "device_nodata" : Object.keys(errorCodes).find(
            (key) => errorCodes[key] === cell.issue);
        } else {
            issueValue = cell.issue === "No Portfolio Data" ? "pf_no_data": cell.issue === "Sites With No Data" ? "partial_nodata" : Object.keys(errorCodes).find(
            (key) => errorCodes[key] === cell.issue);
        }
        if (cell.issue === "No Zone Data" || cell.issue === "No Site Data" || cell.issue === "No Portfolio Data" || cell.issue === "No Tracker Data"){
            const newRowAttrbs = detailTableColumns.map((t) => {
                return {
                    ...t,
                    selected:
                        t.dataField === "start_time" ||
                        t.dataField === "end_time" ||
                        t.dataField === "duration"
                            ? true
                            : false,
                };
            });
            setDtRowAttrbs(newRowAttrbs)
        } else if (cell.issue === "Sites With No Data"){
            const newRowAttrbs = detailTableColumns.map((t) => {
                return {
                    ...t,
                    selected:
                        t.dataField === "available_devices" || t.dataField === "devices_with_issue" || t.dataField === "row_id" || t.dataField === "device_id" || t.dataField === "zone_id" ? false : t.selected,
                };
        })
            setDtRowAttrbs(newRowAttrbs)
        } else if (cell.issue === "Zones With No Data"){
            const newRowAttrbs = detailTableColumns.map((t) => {
                return {
                    ...t,
                    selected:
                        t.dataField === "available_devices" || t.dataField === "devices_with_issue" || t.dataField === "row_id" || t.dataField === "device_id" ? false : t.selected,
                };
        })
            setDtRowAttrbs(newRowAttrbs)
        }
        else {
            setDtRowAttrbs(detailTableColumns)
        }

        console.log("issueValue", issueValue)
        setSelectedRow(cell.issue);
        setSelectedData(childData[issueValue]);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleRowClick = (cell, row) => {
        if (level === "pf") {
            if (row.zone_id && row.device_id){
                storeAction.setDeviceAbs(row.site_id, row.zone_id, row.device_id);
                dataViewAction.clearView();
            } else if (!row.zone_id && !row.device_id){
                storeAction.setSite(row.site_id);
                dataViewAction.clearView();
            }
            }
        if (level === "site") {
            if (row.zone_id && row.device_id){
                storeAction.setDeviceAbs(criteria.site ,row.zone_id, row.device_id);
                dataViewAction.clearView();
            } else if (row.zone_id && !row.device_id){
                storeAction.setZone(row.zone_id);
                dataViewAction.clearView();
            }
        }
        if (level === "zone") {
            if (row.device_id){
                storeAction.setDevice(row.device_id);
                dataViewAction.clearView();
            }
    }
    }

    const handleMouseEnter = (e, row, rowIndex) => {
        if (row.device_id || row.issue || row.zone_id || row.site_id){
            e.currentTarget.style.cursor = "pointer";
            e.currentTarget.style.backgroundColor = "#ededfc";
        }
    };
    const handleMouseLeave = (e, row, rowIndex) => {
        e.currentTarget.style.backgroundColor = "";
    };

    const rowEventsHandler = {
        onClick: handleRowClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
    };

    const handleDropDownSelect = (value) => {
        let newRowAttrbs = null;
        if (value === "Tracker ID"){
            newRowAttrbs = dtRowAttrbs.map((t) => {
                return {
                    ...t,
                    selected:
                        t.dataField === "row_id" || t.dataField === "device_id" ? true : t.dataField === "zone_id" ? false : t.selected,
                };
            });
        } else {
            newRowAttrbs = dtRowAttrbs.map((t) => {
                return {
                    ...t,
                    selected:
                        t.dataField === "row_id" || t.dataField === "device_id" ? false : t.dataField === "zone_id" ? true : t.selected,

                };
            });
        }
        setDtRowAttrbs(newRowAttrbs);
        setSelectedValue(value)
    }

    const headerFormatter = useMemo(() => column => (
        <React.Fragment>
            <span
                onClick={() =>
                    sortColumn(
                        column.dataField,
                        selectedData,
                        setSelectedData,
                        sortedRow,
                        setSortedRow
                    )
                }
                style={{ cursor: "pointer" }}
            >
                {column.text} {showArrow(sortedRow, column.dataField)}
            </span>
        </React.Fragment>
    ), [selectedData, sortedRow]);



    const dtRowAttr = useMemo(() => 
        dtRowAttrbs
            .filter(e => e.selected)
            .map(t => ({ ...t, headerFormatter }))
    , [dtRowAttrbs, headerFormatter]);
        
    return (
        <Styles>
            <div style={{ maxHeight: "320px", overflowY: "auto", overflowX: "auto" }}>
                <BootstrapTable
                    keyField="id"
                    data={memoizedData}
                    columns={memoizedRowAttrbs}
                    rowEvents={{
                        onClick: handleCellClick,
                        onMouseEnter: handleMouseEnter,
                        onMouseLeave: handleMouseLeave,
                    }}
                />
                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    style={{ paddingTop: "70pt" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{`${selectedRow}`}</Modal.Title>
                        <div style={{ position: "absolute", right: "5%" }}>
                            <div>
                                <span style={{ paddingRight: "0.8rem", paddingTop: "1rem" }}>
                                    <InfoTooltip
                                        content={ReactDOMServer.renderToString(tooltipInfo)}
                                        toolTipPointer={"left"}
                                        style={{ marginTop: "16px" }}
                                    />
                                </span>
                                <span className="ml-12 float-right bg-transparent d-flex" style={{ paddingRight: "0.2rem", paddingTop: "0.5rem" }}>
                                    <DownloadCsv_Issue_Detail_table
                                        data={selectedData}
                                        filename={`${fileName}-${selectedRow}`}
                                        level={level}
                                        selectedRow={selectedRow}
                                    />
                                </span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Styles>
                            <div style={{ maxHeight: "610px", overflowY: "auto" }}>
                                <BootstrapTable
                                    keyField="id"
                                    data={selectedData}
                                    columns={dtRowAttr}
                                    rowEvents={rowEventsHandler}
                                />
                            </div>
                        </Styles>
                    </Modal.Body>
                    {level === 'pf' && (
                    <Modal.Footer>
                        <p>
                            ***Disclaimer: The data presented in this table includes records with a duration of 60 minutes or more.
                        </p>
                    </Modal.Footer>
                    )}
                </Modal>
            </div>
        </Styles>
    );
});


export default Table