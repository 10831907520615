import React from 'react';
import { CSVLink } from "react-csv";
import { getLogData, getLevel } from '../../helpers/common';
import { useCriteriaContext } from '../../context/criteria.context';
import { createActivityLog } from '../../utils/apiCall';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

function DownloadCsv(filename, headers, data, detailheaders, detaildata) {
    const csvReport = {
        filename: filename + '.csv',
        headers: headers,
        data: data
    };
    const csvDetailReport = {
        filename: filename + '-detailed.csv',
        headers: detailheaders || [],
        data: detaildata || []
    }
    const criteria = useCriteriaContext()
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const level = getLevel(criteria)
    const logData = getLogData(criteria, {featureId: `download-${level}-summary`, usecase: `download ${level} summary`, category: 'overview', level: level})
    return <>
        {detaildata ? <div className="drop-down-menu" style={{zIndex: 10000}}>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle>
                        <img
                            src="../assets/img/icon/icon-download.svg"
                            alt="Download"
                            width="16"
                            height="16"
                            className="chart-download"
                        />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem
                            onClick={() => createActivityLog(logData)}
                        >
                            <CSVLink {...csvReport}>
                                <img
                                    src="../assets/img/icon/icon-csv.svg"
                                    alt="Download"
                                    width="24"
                                    height="24"
                                    className='chart-download-menu-icon'
                                />
                                Download summary
                            </CSVLink>
                        </DropdownItem>
                        {detaildata ? <DropdownItem
                            onClick={() => createActivityLog(logData)}
                        >
                            <CSVLink {...csvDetailReport}>
                                <img
                                    src="../assets/img/icon/icon-csv.svg"
                                    alt="Download"
                                    width="24"
                                    height="24"
                                    className='chart-download-menu-icon'
                                />
                                Download with details
                            </CSVLink>
                        </DropdownItem> : null}
                    </DropdownMenu>
                </Dropdown>
            </div> : <div className="iop">
            <CSVLink onClick={() => createActivityLog(logData)} {...csvReport}><img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" /></CSVLink>
        </div>}
    </>
}

export function DownloadCsv_Uptime({ data, filename }) {
    try {
        data.map(function (d) {
            var dt = new Date(0)
            dt.setUTCSeconds(d.ts);
            d.ts = dt
        })
        var colname = [
            { label: "Date", key: "ts" },
            { label: "Uptime", key: "uptime" },
        ];
        return DownloadCsv(filename, colname, data)
    } catch {
        return <></>
    }
}

export function DownloadCsv_Operational_Modes({ data, filename }) {
    try {
        data.modemajoritytotal.map(function (d) {
            var modedata = data.allmodes.find(e => e.value === d.value)
            var dt = new Date(0)
            dt.setUTCSeconds(d.ts);
            d.ts = dt
            d.value = modedata.label
        })
        var colname = [
            { label: "ModeType", key: "value" },
            { label: "Date", key: "ts" },
            { label: "Total", key: "total" },
        ];
        return DownloadCsv(filename, colname, data.modemajoritytotal)
    } catch {
        return <></>
    }
}

export function DownloadCsv_Mode_Summary({ data, filename }) {
    try {
        var final_data = []
        data.map(function (d) {
            d.data.map(e => e.label = d.label)
            final_data = final_data.concat(d.data)
        })
        var colname = [
            { label: "Label", key: "label" },
            { label: "Date", key: "x" },
            { label: "Value", key: "y" },
        ];
        return DownloadCsv(filename, colname, final_data)
    } catch {
        return <></>
    }
}

export function DownloadCsv_Error_Summary({ data, filename }) {
    return DownloadCsv_Mode_Summary({ data, filename })
}

export function DownloadCsv_Current_Angle({ data, filename }) {
    return DownloadCsv_Mode_Summary({ data, filename })
}

export function DownloadCsv_Summary_table({ data, filename, detaildata }) {
    try {
        // data.map(function(d){
        // })
        var colname = [
            { label: "SiteID", key: "site_id" },
            { label: "Error Count", key: "errorCount" },
            { label: "Snow Fall", key: "snow_fall" },
            { label: "Availability", key: "uptime" },
            { label: "Availability Impact", key: "uptimeImpact" },
            { label: "Device Count", key: "devicecount" },
            { label: "Wind Direction", key: "wind_direction" },
            { label: "Wind Speed", key: "wind_speed" },
            { label: "Error Codes", key: "errorCodes" },
            { label: "Flood Level", key: "flood_level" },
        ];
        var detailcolname = [
            { label: "SiteID", key: "site_id" },
            { label: "ZoneID", key: "zone_id" },
            // { label: "Snow Fall", key: "snow_fall" },
            { label: "Availability", key: "uptime" },
            { label: "Availability Impact", key: "uptimeImpact" },
            { label: "Device Count", key: "devicecount" },
            { label: "Error Count", key: "errorCount" },
            // { label: "Wind Direction", key: "wind_direction" },
            // { label: "Wind Speed", key: "wind_speed" },
            // { label: "Error Codes", key: "errorCodes" },
            // { label: "Flood Level", key: "flood_level" },
        ];

        return DownloadCsv(filename, colname, data, detailcolname, detaildata)
    } catch {
        return <></>
    }
}

export function DownloadCsv_Site_Summary_table({ data, filename }) {
    try {
        var colname = [
            { label: "ZoneId", key: "zone_id" },
            { label: "Alarms", key: "alarms" },
            { label: "Alarms Count", key: "alarmCount" },
            { label: "Availability", key: "uptime" },
            { label: "Availability Impact", key: "uptimeImpact" },
            { label: "Device Count", key: "devicecount" },
            { label: "Error Count", key: "errorCount" },
        ];
        return DownloadCsv(filename, colname, data)
    } catch {
        return <></>
    }
}

export function DownloadCsv_Zone_Summary_table({ data, filename }) {
    try {
        var colname = [
            { label: "Device_id", key: "device_id" },
            { label: "Alarms", key: "alarms" },
            { label: "Availability", key: "uptime" },
            { label: "Availability Impact", key: "uptimeImpact" },
            { label: "Error Count", key: "errorCount" },
        ];
        return DownloadCsv(filename, colname, data)
    } catch {
        return <></>
    }
}