
import React, {useState, useEffect} from 'react';
import { AvailabilityOverview, ErrorOverview, UptimeOverview, ZonesOverview,EnergyOverview, MaintenanceOverview, RowsOverview } from "./stats.overview";
import { CardStats as Card } from '../layout/Card';
import { useUserContext } from '../../context/user.context';
import { usePageContext, energyMeterPage } from '../../context/page.context';
import { useStore } from '../../context/store.context';

const PortfolioOverview = (props) => {
  const userContext = useUserContext()
  const pageContext = usePageContext();
  const pageName = pageContext.page;

  const energy_urls = [
    { name: "energy", url: "expected_actual", grouptype: "sites", slice: 999, path:"/energy",  aggr: "avg" },
  ]

  const [tenants, setTenants] = useState([])
  const criteria = useStore().criteria

  const zone_urls = [
    {name: "zoneOverviewData", url: "zone_overview_data"}
    // { name: "zonesoffline", url: "zones_offline", slice: 999 },
    // { name: "zonesComm", url: "zones_communicating", slice: 999 },
    // { name: "zones", url: "all_unique_zones", slice: 999, nocache: true },
  ]

  const rows_urls = [
    {name: "tracker_offline_overview_data", url: "tracker_offline_overview_data",grouptype: "pf"}
  ]
  
  return (
    < >
      {props.priorityChart.length === 0 && pageName === energyMeterPage ?<EnergyOverview urls={energy_urls} /> : "" }
      {props.priorityChart.length === 0 ?<UptimeOverview/>: <Card>Loading Uptime Stats ...</Card>}
      {props.priorityChart.length === 0 && pageName !== energyMeterPage ?<AvailabilityOverview/>: ""}
      {props.priorityChart.length === 0 && pageName !== energyMeterPage ?<ZonesOverview urls={zone_urls} />: ""}
      {props.priorityChart.length === 0 && pageName !== energyMeterPage ?<ErrorOverview/>: ""}
      {props.priorityChart.length === 0 && pageName !== energyMeterPage ? <MaintenanceOverview /> : ""}
      {props.priorityChart.length === 0 && pageName !== energyMeterPage ?<RowsOverview urls={rows_urls} />: ""}
    </>
  );
}


export default PortfolioOverview;