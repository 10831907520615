import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import './hail.css';
import { useCriteriaContext } from '../../context/criteria.context';
import { getSites } from '../../model/metadata';
import { postJSONData } from "../model/store.swr";
import styled from 'styled-components'
import { isAdminUser } from "../auth/userinfo";
import { domainsByCriteria } from '../layout/criteria';

// Styled component for displaying error messages
const Error = styled.span`
  padding: 0px;
  padding-top: 30px;
  color: red;
`;

/**
 * Component for managing Stow Settings.
 * @param {function} handleOk - Function to handle OK button click.
 */
const StowSettings = ({handleOk}) => {
  const [editable, setEditable] = useState(true);
  const criteria = useCriteriaContext();
  const domainArray = domainsByCriteria(criteria);
  const siteValueIndex = domainArray.indexOf('site');
  const siteValue = siteValueIndex !== -1 ? criteria.site : null;
  const [sites, setSites] = useState(null);
  const criteriaContext = useCriteriaContext();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editedStowReleaseDelay, setEditedStowReleaseDelay] = useState("");  
  const [editedMaxTilt, setEditedMaxTilt] = useState(""); 
  const [editedAwayStorm, setEditedAwayStorm] = useState(""); 
  const [hailFeatureEnabled, setHailFeatureEnabled] = useState(null); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [autoStowEnabled, setAutoStowEnabled] = useState(null);
  const [data, setData] = useState(null);

    // Effect hook to initialize values from sites
  useEffect(() => {
    initializeValuesFromSites();
}, [handleOk]);

  // Function to initialize values from sites
const initializeValuesFromSites = () => {
  getSites(criteriaContext, (data) => {
    // console.log('Data received:', data);
    setSites(data && data.length > 0 ? data : []);
  }, () => {});
};

  // Effect hook to set initial values
  useEffect(() => {
    const setInitialValues = () => {
      if (editable && criteriaContext.site && sites === null) {
        getSites(criteriaContext, (data) => {
          console.log('Data received:', data);
          setSites(data && data.length > 0 ? data : []);
        }, () => {});
      }
  
      if (criteriaContext.site && sites !== null) {
        const selectedSite = sites.find((site) => site.site_id === siteValue);
  
        if (selectedSite) {
          setEditedMaxTilt(selectedSite.max_tilt || false);  
          setEditedStowReleaseDelay(selectedSite.stow_release_delay !== null ? parseFloat(selectedSite.stow_release_delay) : 30);  
          setEditedAwayStorm(selectedSite.away_from_storm || false); 
          setHailFeatureEnabled(selectedSite.hail_feature_enabled || null);
          setAutoStowEnabled(selectedSite.auto_stow_enabled || null);
        } else {
          setEditedMaxTilt("");  
          setEditedStowReleaseDelay("");  
          setEditedAwayStorm(""); 
          setHailFeatureEnabled(null);
          setAutoStowEnabled(null);
        }
      }
    };

    setInitialValues();
    setErrorMessage('');
    setUnsavedChanges(false); 
  }, [editable, criteriaContext.site, sites, siteValue]);
  
    // Function to handle input change
  const handleInputChange = (e) => {
    if (!editable) return; 
    const { name, value } = e.target || {};

    if (!hailFeatureEnabled) {
      setErrorMessage("In order for users to make the necessary configuration changes, the site's hail feature must be enabled. To activate the Hail feature, please contact the FTC Admin");
      return;
    }
    if (!autoStowEnabled) {
      return;
    }
    let newValue; 
    switch (name) {
      case 'field7':
        if (value.trim() === '') {
            newValue = "";
        } else if (!isNaN(value)) {
          newValue = parseFloat(value);
        } else {
          newValue = 30;
          return;
        }
        setEditedStowReleaseDelay(newValue);
        break;  
      default:
        break;
    }

    setUnsavedChanges(true);
};

  // Function to handle cancel action
  const handleCancel = () => {
    console.log('Form canceled');
    setEditable(true);
    getSites(
      criteriaContext,
      (data) => {
        console.log('Data received:', data);
        setSites(data && data.length > 0 ? data : []);
      }, () => {}
    );
    setUnsavedChanges(false);
    setErrorMessage(""); 
  };

  // Function to handle OK action
  const handleOkk = () => {
    if (editedStowReleaseDelay === '') {
      setErrorMessage("Stow Release Delay value cannot be blank. Please enter the valid delay time.");
      return;
    }
    const postData = {
      siteValue,
      editedMaxTilt,
      editedStowReleaseDelay,
      editedAwayStorm,
    };
  
    console.log('Data submitted:', postData);
  
    const apiUrl = "/meta?querytype=meta_sites_hail_direction"; 
    postJSONData(apiUrl, postData, (response) => {
      console.log('Server response:', response);
      // initializeValuesFromSites();
      getSites(
        criteriaContext,
        (data) => {
          console.log('Data received:', data);
          setSites(data && data.length > 0 ? data : []);
        }, () => {}
      );
        handleSuccessfulStowResponse(response);
        setUnsavedChanges(false);
        setEditable(true);
      });
  };

    // Function to handle successful stow response
  const handleSuccessfulStowResponse = (response) => {
  };

    // Function to handle toggle for Stow at Nearest Max-tilt
  const handleToggle4 = () => {
    if (!hailFeatureEnabled) {
      setErrorMessage("In order for users to make the necessary configuration changes, the site's hail feature must be enabled. To activate the Hail feature, please contact the FTC Admin");
      return;
    }
    if (!autoStowEnabled) {
      return;
    }
    setEditedMaxTilt(true);
    setEditedAwayStorm(false); // Disable toggle5 when toggle4 is enabled
    setUnsavedChanges(true);
  };

    // Function to handle toggle for Stow at Max-tilt Away From The Wind
  const handleToggle5 = () => {
    if (!hailFeatureEnabled) {
      setErrorMessage("In order for users to make the necessary configuration changes, the site's hail feature must be enabled. To activate the Hail feature, please contact the FTC Admin");
      return;
    }
    if (!autoStowEnabled) {
      return;
    }
    setEditedAwayStorm(true);
    setEditedMaxTilt(false); // Disable toggle4 when toggle5 is enabled
    setUnsavedChanges(true);
  };

  return (
    <>
    {(siteValue) && (
    <div className="col-8 col-xl-5 pl-0" style={{ width: 'calc(100% - 20px)' }}>
      <div className="chart-card1">
        <div className="chart-card-header1">
          <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 3px 10px 6px' }}>
            Stow Direction and Release Stow Settings:
          </h5>
        </div>
        <Row></Row>
        <Row></Row>
        <Col md={12}>
        { <Error>{errorMessage}</Error>}
            </Col>
        <Row>
          <Col md={10}></Col>
          <Col md={2} className={isAdminUser() ? 'col-padding-right1' : 'col-padding-right'}>
            <span
              className="tooltip-icon"
              data-tip="<div class='tooltip-content'><b>Stow Direction:</b><br/>Users can select the direction the trackers must face during Hail Stow.
              <br/>Users can choose one of the two options.
              <br/><br/>
               <li> Stow At Nearest Max-Tilt:
               <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Turns trackers to a steep angle in their current tracking direction.
               <br/>
               <li>Stow At Max-Tilt Away from the Wind:
              <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Turns trackers to a steep angle away from the wind.
              <br/><br/>
               <b>Stow Release Delay: </b><br/>The Stow Release Delay is the duration for which trackers will remain 
               <br/>stowed even after the Hail Forecast becomes inactive.
               <br/>This delay is necessary to ensure that the trackers remain in a safe position.
               <br/>By default, the Stow Release Delay is set to 30 minutes. 
               </div>"
              data-html={true}
              data-place="right"
            >
              <img
                src="../assets/img/icon/icon-info.svg"
                width="20"
                height="20"
                size={31}
                alt="Info"
              />
            </span>
            <ReactTooltip effect="solid" />
          </Col>
        </Row>

        <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Stow At Nearest Max-Tilt</label>
        </Col>
        <Col md={5}>
          <div>
            <input
              type="radio"
              id="radioMaxTiltYes"
              name="radioMaxTilt"
              checked={autoStowEnabled ? editedMaxTilt : false}
              onChange={handleToggle4}
              disabled={!editable}
              className="custom-radio"
              />
          </div>
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Stow At Max-Tilt Away From The Wind</label>
        </Col>
        <Col md={5}>
          <div>
            <input
              type="radio"
              id="radioAwayStormYes"
              name="radioAwayStorm"
              checked={autoStowEnabled ? editedAwayStorm : false}
              onChange={handleToggle5}
              disabled={!editable}
              className="custom-radio"
              />
          </div>
        </Col>
      </Row>

        <Row className="d-flex align-items-center">
          <Col md={7}>
            <label className="card-title">Stow Release Delay</label>
          </Col>
          <Col md={5}>
            <Form.Control
              type="number"
              name="field7"
              value={editedStowReleaseDelay}
              onChange={handleInputChange}
              disabled={!editable}                  
              style={{
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px',
                paddingRight: '38px',
              }}
            />
            <div className="unit">min</div>
          </Col>
        </Row>
        <Row></Row>
        <Row className="d-flex align-items-center">
          <Col md={7}></Col>
          <Col md={5}>
            <Button className="btn-secondary" onClick={handleOkk} style={{ width: '47%' }} disabled={!unsavedChanges}>
              Ok
            </Button>
            <Button className="btn-secondary" style={{ width: '47%', marginLeft: '11px' }} onClick={handleCancel} disabled={!unsavedChanges}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </div>
        )}
    </>
  );
};
export default StowSettings;

