import { Form, Button, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect} from 'react';
import moment from 'moment-timezone';
import SingleSelectDropDownControl from './singleSelectDropdown';
import { useCriteriaContext } from '../../context/criteria.context';
import { getSites } from '../../model/metadata';
import { postJSONData } from "../model/store.swr";
import { isAdminUser } from "../auth/userinfo";
import { isIrradianceUser } from '../auth/userinfo';

const IrradianceSettings = ({siteValue , diffuseStatus}) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [gcr, setGcr] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const criteriaContext = useCriteriaContext();
  const [sites, setSites] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [initialLatitude, setInitialLatitude] = useState('');
  const [initialLongitude, setInitialLongitude] = useState('');
  const [initialGcr, setInitialGcr] = useState('');
  const [initialTimezone, setInitialTimezone] = useState(null);
  const [maxRotation, setMaxRotation] = useState('');
  const [initialMaxRotation, setInitialMaxRotation] = useState('');
  const isAdmin = isAdminUser();
  const isIrradianceAdmin = isIrradianceUser();

  // Fetch initial data when criteria or sites change
  useEffect(() => {
    const fetchInitialData = async () => {
      setFetchingData(true);
      if (siteValue && sites === null) {
        getSites(criteriaContext, (data) => {
          setSites(data && data.length > 0 ? data : []);
        }, () => {});
      }

      if (siteValue && sites !== null) {
        const selectedSite = sites.find((site) => site.site_id === siteValue);
        if (selectedSite) {
          const latitude = selectedSite.latitude ||'';
          const longitude = selectedSite.longitude ||'';
          const gcr = selectedSite.gcr || ''
          const timezone = selectedSite.tz || '';
          const maxRotation = selectedSite.max_rotation || '';
          setMaxRotation(maxRotation);
          setLatitude(latitude);
          setLongitude(longitude);
          setGcr(gcr);
          setSelectedTimezone({ value: timezone, label: timezone });
          setInitialMaxRotation(maxRotation);
          setInitialLatitude(latitude);
          setInitialLongitude(longitude);
          setInitialGcr(gcr);
          setInitialTimezone({ value: timezone, label: timezone });
        }
      }
      setFetchingData(false);
    };
      fetchInitialData();
  }, [criteriaContext.site, sites, siteValue]);

   // Fetch timezone options
  useEffect(() => {
    const timezones = moment.tz.names().map(tz => ({
      value: tz,
      label: tz
    }));
    setTimezoneOptions(timezones);
  }, []);

  // Handle changes in form fields
  const handleLatitudeChange = (e) => {
    if (isAdmin && isIrradianceAdmin) {
    setLatitude(e.target.value);
    setIsChanged(true);
    }
  }

  const handleLongitudeChange = (e) => {
    if (isAdmin && isIrradianceAdmin) {
    setLongitude(e.target.value);
    setIsChanged(true);
    }
  }

  const handleGcrChange = (e) => {
    if (isAdmin && isIrradianceAdmin) {
    setGcr(e.target.value);
    setIsChanged(true);
    }
  }

  const handleTimezoneChange = (option) => {
    if (isAdmin && isIrradianceAdmin) {
    setSelectedTimezone(option);
    setIsChanged(true);
    }
  }

  const handleMaxRotationChange = (e) => {
    if (isAdmin && isIrradianceAdmin) {
    setMaxRotation(e.target.value);
    setIsChanged(true);
    }
  }

  const handleSave = () => {

    const payload = {
      Site_id: siteValue,
      Latitude: latitude,
      Longitude: longitude,
      GCR: gcr,
      Timezone: selectedTimezone.value,
      Max_Rotation: maxRotation
    };

    console.log('Data submitted:', payload);
    const apiUrl = "/meta?querytype=meta_irradiance_configurations&restype=json"; 
    postJSONData(apiUrl, payload, (response) => {
      console.log('Server response:', response);

      getSites(criteriaContext, (data) => {
        console.log('Data received:', data);
        setSites(data && data.length > 0 ? data : []);
      }, () => {});
    });

    const data = {
        site_id: siteValue,
        diffuse_status: diffuseStatus,
        }
    postJSONData("/diffuse-pulsar-data?restype=json",data,(res) => {console.log("checking response",res)})
    setIsChanged(false);
    }

  const handleCancel = () => {
    setLatitude(initialLatitude);
    setLongitude(initialLongitude);
    setGcr(initialGcr);
    setSelectedTimezone(initialTimezone);
    setMaxRotation(initialMaxRotation);
    setIsChanged(false);
  }


  return (
    <div className="col-6 pl-0">
    <div className="chart-card1">
      <div className="chart-card-header1">
             <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 0px 10px 6px' }}>
              Irradiance Configuration
            </h5>
              </div>
              <Row></Row>
              <Form>
                  <Form.Group as={Row}>
                                <Form.Label className="card-title" column sm={4}>
                                  Latitude
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                        type="number"
                                        value={latitude}
                                        onChange={handleLatitudeChange}
                                    />
                                </Col>
                                <Col sm={2}></Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="card-title" column sm={4}>
                                  Longitude
                                 </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                        type="number"
                                        value={longitude}
                                        onChange={handleLongitudeChange}
                                    />
                                </Col>
                                <Col sm={2}></Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="card-title" column sm={4}>
                                  GCR
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                        type="number"
                                        value={gcr}
                                        onChange={handleGcrChange}
                                    />
                                </Col>
                                <Col sm={2}></Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="card-title" column sm={4}>
                                  Max Rotation
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                        type="number"
                                        value={maxRotation}
                                        onChange={handleMaxRotationChange}
                                    />
                                </Col>
                                <Col sm={2}></Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                            <Form.Label className="card-title" column sm={4}>
                              Timezone
                            </Form.Label>
                            <Col sm={6}>
                              <SingleSelectDropDownControl
                                value={selectedTimezone}
                                onChange={handleTimezoneChange}
                                options={timezoneOptions}
                                placeholder="Select timezone"
                              />
                            </Col>
                            <Col sm={2}></Col>
                            </Form.Group>
                          <Form.Group as={Row}>
                            <Col md={4}></Col>
                                <Col md={6}>
                                  <Button className="btn-secondary" onClick={handleSave} style={{ width: '47%' }} disabled={!isChanged} >
                                    Save
                                  </Button>
                                  <Button className="btn-secondary" style={{ width: '47%', marginLeft: '11px' }} onClick={handleCancel} disabled={!isChanged}>
                                    Cancel
                                  </Button>
                                </Col>
                                <Col sm={2}></Col>
                            </Form.Group>
                        </Form>
                    </div>
              </div>
            );
        }

export default IrradianceSettings;